import axios from 'src/utils/axios';


class MgmtClassesService {

  createClass = (newClass) => new Promise((resolve, reject) => {

    const payload = newClass;

    //console.log("MgmtClassesService.createClass:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/class/create', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.createClass (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  updateClass = (newClass) => new Promise((resolve, reject) => {

    const payload = newClass;

    //console.log("MgmtClassesService.createClass:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/class/update', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.createClass (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  registerTeacher = (newTeacher) => new Promise((resolve, reject) => {

    const payload = newTeacher;

    //console.log("MgmtClassesService.registerTeacher:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/teacher/register', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.registerTeacher (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  updateTeacher = (newTeacher) => new Promise((resolve, reject) => {

    const payload = newTeacher;

    //console.log("MgmtClassesService.registerTeacher:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/teacher/update', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.registerTeacher (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })


  registerStudent = (newStudent) => new Promise((resolve, reject) => {

    const payload = newStudent;

    //console.log("MgmtClassesService.registerStudent:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/student/register', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.registerStudent (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  updateStudent = (newStudent) => new Promise((resolve, reject) => {

    const payload = newStudent;

    //console.log("MgmtClassesService.registerStudent:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/student/update', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.registerStudent (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  registerBatch = (newBatch) => new Promise((resolve, reject) => {

    const payload = newBatch;
    //console.log("MgmtClassesService.registerBatch:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/batch/create', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.registerBatch (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  updateBatch = (newBatch) => new Promise((resolve, reject) => {

    const payload = newBatch;
    //console.log("MgmtClassesService.registerBatch:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/batch/update', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.registerBatch (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })



  enrollStudents = (batch) => new Promise((resolve, reject) => {
    const payload = batch;
    //console.log("MgmtClassesService.enrollStudents:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/batch/enroll', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.enrollStudents (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  logAttendance = (attendance) => new Promise((resolve, reject) => {
    const payload = attendance;
    //console.log("MgmtClassesService.logAttendance:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/batch/attendance/log', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response) recordAttendance:" + JSON.stringify(response));

      //  TODO: SP decide what data to be pushed and store
      if(response.data.payload){
        resolve(response.data.payload);
      } else {
        reject(null);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.recordAttendance (Response Error):" + JSON.stringify(error));
      reject(error);
    });
    return(null);
  })

  createFeesReceipt = (feeReceipt) => new Promise((resolve, reject) => {
    const payload = feeReceipt;
    //console.log("MgmtClassesService.collectFees:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/fees/receipt/create', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let respInfo = response.data.payload;
        resolve(respInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.collectFees (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateFeeReceipt = (feeReceipt) => new Promise((resolve, reject) => {
    const payload = feeReceipt;
    //console.log("MgmtClassesService.collectFees:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/fees/receipt/update', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let respInfo = response.data.payload;
        resolve(respInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.collectFees (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })


  fetchClassInfo = (businessId, userId) => new Promise((resolve, reject) => {

    const payload = { businessId: businessId, userId: userId};

    //console.log("MgmtClassesService.fetchClassInfo:" + JSON.stringify(payload));

    axios.post('/api/mgmt/classes/info', {payload})
    .then((response) => {
      //console.log("MgmtClassesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.fetchClassInfo (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })



}



const mgmtClassesService = new MgmtClassesService();

export default mgmtClassesService;
