import axios from 'src/utils/axios';


class MgmtCustomerService {

  createCustomer = (newCustomer) => new Promise((resolve, reject) => {

    const payload = newCustomer;

    //console.log("MgmtCustomerService.createCustomer:" + JSON.stringify(payload));

    axios.post('/api/business/customer/create', {payload})
    .then((response) => {
      //console.log("MgmtCustomeresService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let classesInfo = response.data.payload;
        resolve(classesInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtCustomerService.createCustomer (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateCustomer = (editCustomer) => new Promise((resolve, reject) => {

    const payload = editCustomer;

    //console.log("MgmtCustomerService.updateCustomer:" + JSON.stringify(payload));

    axios.post('/api/business/customer/update', {payload})
    .then((response) => {
      //console.log("MgmtCustomeresService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let customer = response.data.payload;
        resolve(customer);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtCustomerService.createCustomer (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  fetchCustomerInfo = (businessId, userId) => new Promise((resolve, reject) => {

    const payload = { businessId: businessId, userId: userId};

    //console.log("MgmtCustomerService.fetchCustomerInfo:" + JSON.stringify(payload));

    axios.get('/api/business/customers', { params: {businessId : businessId}})
    .then((response) => {
      //console.log("MgmtCustomerService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let customers = response.data.payload;
        resolve(customers);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.fetchClassInfo (Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })





}



const mgmtCustomerService = new MgmtCustomerService();

export default mgmtCustomerService;
