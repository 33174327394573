class NewBusinessReqInfo {

  constructor(businessInfo) {
    //console.log('NewBusinessReqInfo: ' + JSON.stringify(businessInfo));

    this.businessId = businessInfo.businessId;
    this.businessName = businessInfo.businessName;
    this.businessEmail = businessInfo.businessEmail;
    this.ownerName = businessInfo.ownerName;
    this.businessMobile = businessInfo.businessMobile;
    this.businessType = businessInfo.businessType;
    this.password = businessInfo.password;
  }
}

class BusinessInfo {

  constructor(businessId, updatedBy, info) {
    //console.log('BusinessInfo(): ' + JSON.stringify(info));

    this.businessId = businessId;
    this.businessName = info.businessName;

    this.contacts = info.contacts;
    this.address = {
      address1: info.address1,
      address2: info.address2,
      city: info.city,
      state: info.state,
      country: info.country,
      pincode: info.pincode
    }
    this.updatedBy = updatedBy;
  }
}

class BusinessAccountInfo {

  constructor(businessId, updatedBy, info) {
    //console.log('BusinessInfo(): ' + JSON.stringify(info));

    this.businessId = businessId;
    //this.businessName = info.businessName;

    this.accountant = {
      name: info.authorizedBy,
      designation: info.designation,
      email: info.email,
      mobile: info.mobile,
    };

    this.bankDetails = {
      bankifsc: info.bankifsc,
      bankac: info.bankac,
    };

    this.updatedBy = updatedBy;
  }
}

class BusinessTaxInfo {

  constructor(businessId, updatedBy, info) {
    //console.log('BusinessInfo(): ' + JSON.stringify(info));

    let stateArray = info.statename.split(",");

    this.businessId = businessId;
    //this.businessName = info.businessName;

    this.tax = {
      taxType: info.taxType,
      compositeTaxRate: info.compositeTaxRate,
      gstin: info.gstin,
      pan: info.pan,
      statecode: stateArray[0],
      statename: stateArray[1],
    };
    this.updatedBy = updatedBy;
  }
}

class BusinessTnC {

  constructor(businessId, updatedBy, info) {
    //console.log('BusinessInfo(): ' + JSON.stringify(info));

    this.businessId = businessId;
    //this.businessName = info.businessName;
    this.tnc = info.tnc;
    this.updatedBy = updatedBy;
  }
}

class BusinessUserInfo {

  constructor(businessId, updatedBy, info) {
    //console.log('BusinessInfo(): ' + JSON.stringify(info));

    //let stateArray = info.statename.split(",");

    this.businessId = businessId;
    //this.businessName = info.businessName;

    this.username = info.username;
    this.email = info.email;
    this.mobile = info.mobile;
    this.role = info.role;
    this.allowedIP = info.allowedIP;

    this.updatedBy = updatedBy;
  }
}

class BusinessUserList {

  constructor(businessId, updatedBy, userList, action) {
    //console.log('BusinessUserList(): ' + JSON.stringify(userList));

    this.businessId = businessId;
  //  this.businessName = businessId;
    this.userList = userList;
    this.action = action;


    this.updatedBy = updatedBy;
  }
}


class BusinessLogo {

  constructor(businessId, updatedBy, data) {
    //console.log('BusinessLogo(): ', data);

    for (var key of data.entries()) {
        //console.log("BusinessLogo" + key[0] + ', ' + key[1]);
    }

    //let stateArray = info.statename.split(",");

    this.businessId = businessId;
    this.businessName = businessId;

    this.file = data;

    this.updatedBy = updatedBy;
  }
}




export default { NewBusinessReqInfo };
export { NewBusinessReqInfo, BusinessInfo, BusinessAccountInfo, BusinessTaxInfo, BusinessUserInfo, BusinessUserList, BusinessTnC, BusinessLogo };
