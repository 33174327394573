import mgmtClassesService from 'src/services/mgmtClassesService';

import { Teacher, ClassInfo, Student, Batch, BatchStudents, Attendance, FeeReceipt } from 'src/interface/classesIf';

export const CLASSES_CLASS_INFO ='@clasess/class/info';

export const CLASSES_CLASS_CREATE ='@classes/class/create';
export const CLASSES_CLASS_CREATE_SUCCESS ='@classes/class/create-success';
export const CLASSES_CLASS_CREATE_FAILURE ='@classes/class/create-failure';

export const CLASSES_CLASS_UPDATE ='@classes/class/update';
export const CLASSES_CLASS_UPDATE_SUCCESS ='@classes/class/update-success';
export const CLASSES_CLASS_UPDATE_FAILURE ='@classes/class/update-failure';

export const CLASSES_TEACHER_REGISTER ='@classes/teacher/register';
export const CLASSES_TEACHER_REGISTER_SUCCESS ='@classes/teacher/register-success';
export const CLASSES_TEACHER_REGISTER_FAILURE ='@classes/teacher/register-failure';

export const CLASSES_TEACHER_UPDATE ='@classes/teacher/update';
export const CLASSES_TEACHER_UPDATE_SUCCESS ='@classes/teacher/update-success';
export const CLASSES_TEACHER_UPDATE_FAILURE ='@classes/teacher/update-failure';

export const CLASSES_STUDENT_REGISTER ='@classes/student/register';
export const CLASSES_STUDENT_REGISTER_SUCCESS ='@classes/student/register-success';
export const CLASSES_STUDENT_REGISTER_FAILURE ='@classes/student/register-failure';

export const CLASSES_STUDENT_UPDATE ='@classes/student/update';
export const CLASSES_STUDENT_UPDATE_SUCCESS ='@classes/student/update-success';
export const CLASSES_STUDENT_UPDATE_FAILURE ='@classes/student/update-failure';

export const CLASSES_BATCH_REGISTER ='@classes/batch/register';
export const CLASSES_BATCH_REGISTER_SUCCESS ='@classes/batch/register-success';
export const CLASSES_BATCH_REGISTER_FAILURE ='@classes/batch/register-failure';

export const CLASSES_BATCH_UPDATE ='@classes/batch/update';
export const CLASSES_BATCH_UPDATE_SUCCESS ='@classes/batch/update-success';
export const CLASSES_BATCH_UPDATE_FAILURE ='@classes/batch/update-failure';

export const CLASSES_ENROLL_STUDENTS ='@classes/batch/enroll';
export const CLASSES_ENROLL_STUDENTS_SUCCESS ='@classes/batch/enroll-success';
export const CLASSES_ENROLL_STUDENTS_FAILURE ='@classes/batch/enroll-failure';

export const CLASSES_LOG_ATTENDANCE = '@classes/batch/attendance/log';
export const CLASSES_LOG_ATTENDANCE_SUCCESS = '@classes/batch/attendance/log-success';
export const CLASSES_LOG_ATTENDANCE_FAILURE = '@classes/batch/attendance/log-failure';

export const CLASSES_FEES_COLLECT = '@classes/batch/fees/collect';
export const CLASSES_FEES_COLLECT_SUCCESS = '@classes/batch/fees/collect-success';
export const CLASSES_FEES_COLLECT_FAILURE = '@classes/batch/fees/collect-failure';

export const CLASSES_FEES_UPDATE_RECEIPT = '@classes/batch/fees/receipt/update';
export const CLASSES_FEES_UPDATE_RECEIPT_SUCCESS = '@classes/batch/fees/receipt/update-success';
export const CLASSES_FEES_UPDATE_RECEIPT_FAILURE = '@classes/batch/fees/receipt/update-failure';



export function createClass(businessId, userId, classInfo) {
  return async (dispatch) => {

    let newClass = new ClassInfo(classInfo);
    newClass.businessId = businessId;
    newClass.addedBy = userId;

    //console.log("Create Class Action:" + JSON.stringify(classInfo) + " " + JSON.stringify(newClass));

    try {
      dispatch({ type: CLASSES_CLASS_CREATE });

      const classesInfo = await mgmtClassesService.createClass(newClass);

      //console.log("Create Class Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_CLASS_CREATE_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_CLASS_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateClass(businessId, userId, classInfo) {
  return async (dispatch) => {

    let newClass = new ClassInfo(classInfo);
    newClass.businessId = businessId;
    newClass.addedBy = userId;

    //console.log("Create Class Action:" + JSON.stringify(classInfo) + " " + JSON.stringify(newClass));

    try {
      dispatch({ type: CLASSES_CLASS_UPDATE });

      const classesInfo = await mgmtClassesService.updateClass(newClass);

      //console.log("Create Class Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_CLASS_UPDATE_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_CLASS_UPDATE_FAILURE });
      throw error;
    }
  };
}


export function registerTeacher(businessId, userId, teacher) {
  return async (dispatch) => {

    let newTeacher = new Teacher(teacher);
    newTeacher.businessId = businessId;
    newTeacher.addedBy = userId;

    //console.log("Register Teacher Action:" + JSON.stringify(teacher) + " " + JSON.stringify(newTeacher));

    try {
      dispatch({ type: CLASSES_TEACHER_REGISTER });

      const classesInfo = await mgmtClassesService.registerTeacher(newTeacher);

      //console.log("Register Teacher Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_TEACHER_REGISTER_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_TEACHER_REGISTER_FAILURE });
      throw error;
    }
  };
}

export function updateTeacher(businessId, userId, teacher) {
  return async (dispatch) => {

    let newTeacher = new Teacher(teacher);
    newTeacher.businessId = businessId;
    newTeacher.addedBy = userId;

    //console.log("Register Teacher Action:" + JSON.stringify(teacher) + " " + JSON.stringify(newTeacher));

    try {
      dispatch({ type: CLASSES_TEACHER_UPDATE });

      const classesInfo = await mgmtClassesService.updateTeacher(newTeacher);

      //console.log("Register Teacher Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_TEACHER_UPDATE_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_TEACHER_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function registerStudent(businessId, userId, student) {
  return async (dispatch) => {

    let newStudent = new Student(student);
    newStudent.businessId = businessId;
    newStudent.addedBy = userId;

    //console.log("Register Student Action:" + JSON.stringify(student) + " " + JSON.stringify(newStudent));

    try {
      dispatch({ type: CLASSES_STUDENT_REGISTER });

      const classesInfo = await mgmtClassesService.registerStudent(newStudent);

      //console.log("Register Student Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_STUDENT_REGISTER_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_STUDENT_REGISTER_FAILURE });
      throw error;
    }
  };
}

export function updateStudent(businessId, userId, student) {
  return async (dispatch) => {

    let newStudent = new Student(student);
    newStudent.businessId = businessId;
    newStudent.addedBy = userId;

    //console.log("Register Student Action:" + JSON.stringify(student) + " " + JSON.stringify(newStudent));

    try {
      dispatch({ type: CLASSES_STUDENT_UPDATE });

      const classesInfo = await mgmtClassesService.updateStudent(newStudent);

      //console.log("Register Student Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_STUDENT_UPDATE_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_STUDENT_UPDATE_FAILURE });
      throw error;
    }
  };
}


export function createBatch(businessId, userId, batch) {
  return async (dispatch) => {

    let newBatch = new Batch(batch);
    newBatch.businessId = businessId;
    newBatch.addedBy = userId;

    //console.log("Register Batch Action:" + JSON.stringify(batch) + " " + JSON.stringify(newBatch));

    try {
      dispatch({ type: CLASSES_BATCH_REGISTER });

      const classesInfo = await mgmtClassesService.registerBatch(newBatch);

      //console.log("Register Batch Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_BATCH_REGISTER_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_BATCH_REGISTER_FAILURE });
      throw error;
    }
  };
}

export function updateBatch(businessId, userId, batch) {
  return async (dispatch) => {

    let newBatch = new Batch(batch);
    newBatch.businessId = businessId;
    newBatch.addedBy = userId;

    try {
      dispatch({ type: CLASSES_BATCH_UPDATE });

      const classesInfo = await mgmtClassesService.updateBatch(newBatch);

      //console.log("Register Student Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_BATCH_UPDATE_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_BATCH_UPDATE_FAILURE });
      throw error;
    }
  };
}


export function enrollStudents(businessId, userId, batch) {
  return async (dispatch) => {

    let batchStudents = new BatchStudents(batch);
    batchStudents.businessId = businessId;
    batchStudents.addedBy = userId;

    //console.log("Enroll Students:" + JSON.stringify(batchStudents)); // + " " + JSON.stringify(newStudent));

    try {
      dispatch({ type: CLASSES_ENROLL_STUDENTS });

      const classesInfo = await mgmtClassesService.enrollStudents(batchStudents);

      //console.log("Enroll Students Action(after):" + JSON.stringify(classesInfo));
      dispatch({
        type: CLASSES_ENROLL_STUDENTS_SUCCESS,
        payload: {
          classesInfo     // TODO: Gets Full Classes Info
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_ENROLL_STUDENTS_FAILURE });
      throw error;
    }
  };
}


export async function logAttendance(businessId, userId, batchId, attendanceDate, presence, students) {

  //console.log("Log Attendance Entry:" + businessId + userId + attendanceDate + presence + students);

    //console.log("Log Attendance: Dispatch");

    try {
      let attendance = new Attendance(batchId, attendanceDate, presence, students);
      attendance.businessId = businessId;
      attendance.addedBy = userId;
      //console.log("Log Attendance:" + JSON.stringify(attendance)); // + " " + JSON.stringify(newStudent));

    //  dispatch({ type: CLASSES_LOG_ATTENDANCE });

    //  const respInfo = await mgmtClassesService.logAttendance(attendance);
      const respInfo = await mgmtClassesService.logAttendance(attendance);

      //console.log("Log AttendanceAction(after):" + JSON.stringify(respInfo));
      return respInfo;
  /*    dispatch({
        type: CLASSES_LOG_ATTENDANCE_SUCCESS,
        payload: {
          respInfo
        //  classesInfo     // TODO: Gets Full Classes Info
        }
      });*/
    } catch (error) {
      //console.log("Log AttendanceAction(failure):");
    //  dispatch({ type: CLASSES_LOG_ATTENDANCE_FAILURE });
      throw error;
    }
    return null;

//  };
}

export function createFeesReceipt(businessId, userId, receipt) {
  //console.log("createFeesReceipt" + receipt);
  return async (dispatch) => {

    let newReceipt = new FeeReceipt(receipt);
    newReceipt.businessId = businessId;
    newReceipt.addedBy = userId;

    //console.log("CollectFees Details:" + JSON.stringify(newReceipt)); // + " " + JSON.stringify(newStudent));

    try {
      dispatch({ type: CLASSES_FEES_COLLECT });

      const respInfo = await mgmtClassesService.createFeesReceipt(newReceipt);

      //console.log("CollectFees Action(after):" + JSON.stringify(respInfo));
      dispatch({
        type: CLASSES_FEES_COLLECT_SUCCESS,
        payload: {
          respInfo     // TODO: Check what needs to be done here
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_FEES_COLLECT_FAILURE });
      throw error;
    }
  };
}

export function updateFeeReceipt(businessId, userId, receipt) {
  //console.log("createFeesReceipt" + receipt);
  return async (dispatch) => {

    let newReceipt = new FeeReceipt(receipt);
    newReceipt.businessId = businessId;
    newReceipt.addedBy = userId;

    //console.log("CollectFees Details:" + JSON.stringify(newReceipt)); // + " " + JSON.stringify(newStudent));

    try {
      dispatch({ type: CLASSES_FEES_UPDATE_RECEIPT });

      const respInfo = await mgmtClassesService.updateFeeReceipt(newReceipt);

      //console.log("CollectFees Action(after):" + JSON.stringify(respInfo));
      dispatch({
        type: CLASSES_FEES_UPDATE_RECEIPT_SUCCESS,
        payload: {
          respInfo     // TODO: Check what needs to be done here
        }
      });
    } catch (error) {
      dispatch({ type: CLASSES_FEES_UPDATE_RECEIPT_FAILURE });
      throw error;
    }
  };
}


export function setClassesData(classesInfo) {
  return (dispatch) => dispatch({
    type: CLASSES_CLASS_INFO,
    payload: {
      classesInfo
    }
  });
}
