import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';

import mgmtClassesReducer from './mgmtClassesReducer';
import servicesReducer from './servicesReducer';
import productsReducer from './productsReducer';
import ordersReducer from './ordersReducer';
import invoicesReducer from './invoicesReducer';
import customersReducer from './customersReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  form: formReducer,
  mgmtClasses: mgmtClassesReducer,
  services: servicesReducer,
  products: productsReducer,
  orders: ordersReducer,
  customers: customersReducer,
});

export default rootReducer;
