
import axios from 'src/utils/axios';



class BusinessService {

  registerBusiness = (businessInfo) => new Promise((resolve, reject) => {

    //console.log("Register Business Service:" + JSON.stringify(businessInfo));

    axios.post('/api/business/register', {businessInfo})
    .then((response) => {
      //console.log("Register Business Service(Response):" + JSON.stringify(response));

      if(response.data.payload){
        resolve(response.data.payload.business);
      } else {
        reject(response.data.error);
      }

    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" ,error);
      reject(error);
    });
  })

  activateBusiness = (businessToken) => new Promise((resolve, reject) => {

    //console.log("activateBusiness Service:" + JSON.stringify(businessToken));

    const payload = businessToken;

    axios.post('/api/business/activate', {payload})
    .then((response) => {
      //console.log("activateBusiness(response)" + JSON.stringify(response));

      if(response.data.error){
        reject(response.data.error);
      } else {
        resolve(response.data.message);
      }

    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  activateBusinessUser = (businessUserToken) => new Promise((resolve, reject) => {

    //console.log("activateBusinessUser Service:" + JSON.stringify(businessUserToken));

    const payload = businessUserToken;

    axios.post('/api/business/activate-user', {payload})
    .then((response) => {
      //console.log("activateBusinessUser(response)" + JSON.stringify(response));

      if(response.data.error){
        reject(response.data.error);
      } else {
        resolve(response.data.message);
      }

    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })


  updateBusinessInfo = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Info:" + JSON.stringify(payload));

    axios.post('/api/business/update/info', {payload})
    .then((response) => {
      //console.log("Update Business Service(Response):" + JSON.stringify(response));

      if(response.data.payload){
        resolve(response.data.payload.business.businessInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  updateBusinessInfoAccounts = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Info:" + JSON.stringify(payload));

    axios.post('/api/business/update/account', {payload})
    .then((response) => {
      //console.log("Update Business Service(Response):" + JSON.stringify(response));

      // TODO : Sunil check what needs to be set here full info or only account
      if(response.data.payload){
        resolve(response.data.payload.business.businessInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  updateBusinessInfoTax = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Info:" + JSON.stringify(payload));

    axios.post('/api/business/update/tax', {payload})
    .then((response) => {
      //console.log("Update Business Service(Response):" + JSON.stringify(response));

      // TODO : Sunil check what needs to be set here full info or only account
      if(response.data.payload){
        resolve(response.data.payload.business.businessInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })

  updateBusinessTnC = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Info:" + JSON.stringify(payload));

    axios.post('/api/business/update/tnc', {payload})
    .then((response) => {
      //console.log("Update Business Service(Response):" + JSON.stringify(response));

      // TODO : Sunil check what needs to be set here full info or only account
      if(response.data.payload){
        resolve(response.data.payload.business.businessInfo);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });

  })




  addBusinessUser = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Info: Add User:" + JSON.stringify(payload));

    axios.post('/api/business/add-user', {payload})
    .then((response) => {
      //console.log("Update Business Service(Response):" + JSON.stringify(response));

      // TODO : Sunil check what needs to be set here full info or only account
      if(response.data.payload){
        resolve(response.data.payload.businessUser);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateBusinessUser = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Info: Add User:" + JSON.stringify(payload));

    axios.post('/api/business/update-user', {payload})
    .then((response) => {
      //console.log("Update Business Service(Response):" + JSON.stringify(response));

      // TODO : Sunil check what needs to be set here full info or only account
      if(response.data.payload){
        resolve(response.data.payload.businessUser);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  removeBusinessUser = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Info: Remove User:" + JSON.stringify(payload));

    axios.post('/api/business/remove-user', {payload})
    .then((response) => {
      //console.log("Update Business Service(Response - Remove):" + JSON.stringify(response));

      // TODO : Sunil check what needs to be set here full info or only account
      if(response.data.payload){
        resolve(response.data.payload.businessUser);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  blockBusinessUser = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Info: Block User:" + JSON.stringify(payload));

    axios.post('/api/business/block-user', {payload})
    .then((response) => {
      //console.log("Update Business Service(Response - Block):" + JSON.stringify(response));

      // TODO : Sunil check what needs to be set here full info or only account
      if(response.data.payload){
        resolve(response.data.payload.businessUser);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateBusinessLogo = (payload) => new Promise((resolve, reject) => {

    //console.log("Update Business Logo:" + JSON.stringify(payload));

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    axios.post('/api/business/update/logo', payload)
    .then((response) => {
      //console.log("Update Business Service(Response - Logo):" + JSON.stringify(response));

      // TODO : Sunil check what needs to be set here full info or only account
      if(response.data.payload){
        resolve(response.data.payload.businessUser);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("Register Business Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })



}



const businessService = new BusinessService();

export default businessService;
