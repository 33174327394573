import axios from 'src/utils/axios';


class MgmtInvoiceService {

  createInvoice = (newInvoice) => new Promise((resolve, reject) => {

    const payload = newInvoice;

    //console.log("MgmtInvoiceService.createInvoice:" + JSON.stringify(payload));

    axios.post('/api/accounts/invoice/create', {payload})
    .then((response) => {
      ////console.log("MgmtInvoiceesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let invoice = response.data.payload;
        resolve(invoice);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtInvoiceService.createInvoice (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateInvoice = (editInvoice) => new Promise((resolve, reject) => {

    const payload = editInvoice;

    //console.log("MgmtInvoiceService.updateInvoice:" + JSON.stringify(payload));

    axios.post('/api/accounts/invoice/update', {payload})
    .then((response) => {
      //console.log("MgmtInvoiceesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let invoice = response.data.payload;
        resolve(invoice);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtInvoiceService.createInvoice (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

}



const mgmtInvoiceService = new MgmtInvoiceService();

export default mgmtInvoiceService;
