/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminAuthGuard from 'src/components/AdminAuthGuard';

import AdminDashboardLayout from 'src/layouts/AdminDashboardLayout';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/admin_base/login',
    component: lazy(() => import('src/views/auth/AdminLoginView'))
  },
  {
    exact: true,
    path: '/admin_base/login-unprotected',
    component: lazy(() => import('src/views/auth/AdminLoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/activate-business-user/:businessId/:email/:token',
    component: lazy(() => import('src/views/auth/ActivateUserLoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/activate-business-user/',
    component: lazy(() => import('src/views/auth/ActivateUserLoginView'))
  },
  {
    exact: true,
    path: '/register-business-user-unprotected',
    component: lazy(() => import('src/views/auth/ActivateUserLoginView'))
  },


  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/activate-business/:email/:token',
    component: lazy(() => import('src/views/business/ActivateBusinessView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/activate-business/',
    component: lazy(() => import('src/views/business/ActivateBusinessView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/business/ActivateBusinessView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register-business',
    component: lazy(() => import('src/views/business/RegisterBusinessView'))
  },
  {
    exact: true,
    path: '/register-business-unprotected',
    component: lazy(() => import('src/views/business/RegisterBusinessView'))
  },
  {
    path: '/admin_base',
    guard: AdminAuthGuard,
    layout: AdminDashboardLayout,
    routes: [
      {
        exact: true,
        path: '/admin_base',
        component: () => <Redirect to="/admin_base/dashboard" />
      },
      {
        exact: true,
        path: '/admin_base/dashboard',
        component: lazy(() => import('src/views/dashboards/AdminDashboardView'))
      },
    ]
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboards/dashboard" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/account/management',
        component: lazy(() => import('src/views/pages/BusinessAccountView'))
      },
      {
        exact: true,
        path: '/app/account/management/:tabId/:actId',
        component: lazy(() => import('src/views/pages/BusinessAccountView'))
      },
      {
        exact: true,
        path: '/app/dashboards/dashboard',
        component: lazy(() => import('src/views/dashboards/DashboardView'))
        // component: lazy(() => import('src/views/management/classes/reports/ClassesDashboardView'))
      },
      {
        exact: true,
        path: '/app/dashboards/dashboard-financial',
        component: lazy(() => import('src/views/dashboards/DashboardFinancialView'))
      },
      {
        exact: true,
        path: '/app/dashboards/dashboard-alternate',
        component: lazy(() => import('src/views/dashboards/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/dashboards/dashboard" />
      },

      {
        exact: true,
        path: '/app/accounts/sales/receipts',
        component: lazy(() => import('src/views/accounts/sales/ReceiptsListView'))
      },
      {
        exact: true,
        path: '/app/accounts/sales/receipts/create',
        component: lazy(() => import('src/views/accounts/sales/ReceiptCreateView'))
      },
      {
        exact: true,
        path: '/app/accounts/sales/receipt/:receiptId',
        component: lazy(() => import('src/views/accounts/sales/ReceiptDetailsView'))
      },
      {
        exact: true,
        path: '/app/accounts/sales/receipt/edit/:receiptId',
        component: lazy(() => import('src/views/accounts/sales/ReceiptEditView'))
      },

      {
        exact: true,
        path: '/app/accounts/purchase/receipts',
        component: lazy(() => import('src/views/accounts/purchase/ReceiptsListView'))
      },
      {
        exact: true,
        path: '/app/accounts/purchase/receipts/create',
        component: lazy(() => import('src/views/accounts/purchase/ReceiptCreateView'))
      },
      {
        exact: true,
        path: '/app/accounts/purchase/receipt/:receiptId',
        component: lazy(() => import('src/views/accounts/purchase/ReceiptDetailsView'))
      },
      {
        exact: true,
        path: '/app/accounts/purchase/receipt/edit/:receiptId',
        component: lazy(() => import('src/views/accounts/purchase/ReceiptEditView'))
      },

      {
        exact: true,
        path: '/app/management/classes',
        component: lazy(() => import('src/views/management/classes/ClassesView'))
      },
      {
        exact: true,
        path: '/app/management/classes/create',
        component: lazy(() => import('src/views/management/classes/ClassCreateView'))
      },
      {
        exact: true,
        path: '/app/management/classes/edit/:classId',
        component: lazy(() => import('src/views/management/classes/ClassEditView'))
      },

      {
        exact: true,
        path: '/app/management/classes/teacher/register',
        component: lazy(() => import('src/views/management/classes/TeacherRegisterView'))
      },

      {
        exact: true,
        path: '/app/management/classes/teacher/edit/:empId',
        component: lazy(() => import('src/views/management/classes/TeacherEditView'))
      },

      {
        exact: true,
        path: '/app/management/classes/student/register',
        component: lazy(() => import('src/views/management/classes/StudentRegisterView'))
      },
      {
        exact: true,
        path: '/app/management/classes/student/edit/:studentId',
        component: lazy(() => import('src/views/management/classes/StudentEditView'))
      },

      {
        exact: true,
        path: '/app/management/classes/batch/create',
        component: lazy(() => import('src/views/management/classes/BatchCreateView'))
      },
      {
        exact: true,
        path: '/app/management/classes/batch/edit/:batchId',
        component: lazy(() => import('src/views/management/classes/BatchEditView'))
      },


      {
        exact: true,
        path: '/app/management/classes/batch/enroll',
        component: lazy(() => import('src/views/management/classes/EnrollStudentsView'))
      },
      {
        exact: true,
        path: '/app/management/classes/batch/attendance',
        component: lazy(() => import('src/views/management/classes/LogAttendanceView'))
      },
      {
        exact: true,
        path: '/app/management/classes/attendance',
        component: lazy(() => import('src/views/management/classes/AttendanceView'))
      },
      {
        exact: true,
        path: '/app/management/classes/fees/collect',
        component: lazy(() => import('src/views/management/classes/CollectFeesView'))
      },
      {
        exact: true,
        path: '/app/management/classes/fees/receipt/:receiptId',
        component: lazy(() => import('src/views/management/classes/FeeReceiptDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/classes/fees/edit/:receiptId',
        component: lazy(() => import('src/views/management/classes/EditFeesView'))
      },
      {
        exact: true,
        path: '/app/management/classes/fees',
        component: lazy(() => import('src/views/management/classes/FeesView'))
      },

      {
        exact: true,
        path: '/app/dashboards/dashboard-classes',
        component: lazy(() => import('src/views/dashboards/DashboardClassesView'))
      },

      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/create',
        component: lazy(() => import('src/views/management/customer/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:email',
        component: lazy(() => import('src/views/management/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/edit/:email',
        component: lazy(() => import('src/views/management/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/services',
        component: lazy(() => import('src/views/management/services/ServiceListView'))
      },
      {
        exact: true,
        path: '/app/management/services/create',
        component: lazy(() => import('src/views/management/services/ServiceCreateView'))
      },
      {
        exact: true,
        path: '/app/management/services/edit/:serviceCode',
        component: lazy(() => import('src/views/management/services/ServiceEditView'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/products/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/management/products/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/products/edit/:productCode',
        component: lazy(() => import('src/views/management/products/ProductEditView'))
      },

        // TODO_SP: Check and remove the below if not needed
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders/create',
        component: lazy(() => import('src/views/management/order/OrderCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/edit/:orderId',
        component: lazy(() => import('src/views/management/order/OrderEditView'))
      },
      {
        exact: true,
        path: '/app/management/orders/split/:orderId',
        component: lazy(() => import('src/views/management/order/OrderSplitView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/hr/employee/create',
        component: lazy(() => import('src/views/management/hr/employee/EmpCreateView'))
      },
      {
        exact: true,
        path: '/app/hr/employee/employees',
        component: lazy(() => import('src/views/management/hr/employee/EmployeeListView'))
      },
      {
        exact: true,
        path: '/app/hr/employee/employees/:empId',
        component: lazy(() => import('src/views/management/hr/employee/EmpCreateView'))
      },



      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          '/app/chat/new',
          '/app/chat/:threadKey'
        ],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/ApiCallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/products/ClassesManagement',
        component: lazy(() => import('src/views/pages/Products/ProductClassesView'))
      },
      {
        exact: true,
        path: '/products/ServicesManagement',
        component: lazy(() => import('src/views/pages/Products/ServiceClassesView'))
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  const { user } = useSelector(state => state.account);

  //console.log("Routes: ", user);


  return renderRoutes(routesConfig);
}

export default Routes;


/*
<Guard>
  <Layout>
    {route.path === "/app/management/classes/create" ? <Redirect to="/404" /> : route.routes
      ? renderRoutes(route.routes)
      : <Component {...props} />}
  </Layout>
</Guard>
*/
