import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

function ContactInfo({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="left"
          color="textPrimary"
        >
          Contact Us
          <br />
          <br />
        </Typography>

        <Typography
          variant="h3"
          align="left"
          color="secondary"
        >
          Please write us for any queries related to product on email : <a href="mailto:khatacorner@gmail.com" target="_top">khatacorner@gmail.com</a>
        </Typography>
      </Container>
    </div>
  );
}

ContactInfo.propTypes = {
  className: PropTypes.string
};

export default ContactInfo;
