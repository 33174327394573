import axios from 'src/utils/axios';


class MgmtProductService {

  createProduct = (newProduct) => new Promise((resolve, reject) => {

    const payload = newProduct;

    //console.log("MgmtProductProduct.createProduct:" + JSON.stringify(payload));

    axios.post('/api/accounts/product/create', {payload})
    .then((response) => {
      //console.log("MgmtProductService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let products = response.data.payload;
        resolve(products);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateProduct = (editProduct) => new Promise((resolve, reject) => {

    const payload = editProduct;

    //console.log("MgmtProductService.updateService:" + JSON.stringify(payload));

    axios.post('/api/accounts/product/update', {payload})
    .then((response) => {
      //console.log("MgmtProductProduct(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let product = response.data.payload;
        resolve(product);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtProductService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })


  fetchProductInfo = (businessId, userId) => new Promise((resolve, reject) => {

    const payload = { businessId: businessId, userId: userId};

    //console.log("MgmtProductService.fetchProductInfo:" + JSON.stringify(payload));

    axios.get('/api/accounts/product/products', { params: {businessId : businessId}})
    .then((response) => {
      //console.log("MgmtProductService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let products = response.data.payload;
        resolve(products);
      } else {
        resolve(null);
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.fetchClassInfo (Response Error):" + JSON.stringify(error));
      resolve(null);
      reject(error);
    });

  })


}



const mgmtProductService = new MgmtProductService();

export default mgmtProductService;
