import React, { useRef, useState } from 'react';
import axios from 'src/utils/axios';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import { switchBusiness } from 'src/actions/accountActions';
import BusinessIcon from '@material-ui/icons/Business';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Business({ user }) {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const businessList = account.user.businessList;
  const userId = account.user.email;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitchBusiness = businessId => async event => {
    try {
      handleClose();

      //console.log('handleSwitchBusiness' + businessId);
      if (account.user.activeBusinessId != businessId) {
        dispatch(switchBusiness(userId, account.user.activeBusinessId, businessId));
        //setStatus({ success: true});  //TODO SP: Check what happens with this

        //axios.post('/app/dashboards/dashboard');
        history.push('/app/dashboards/dashboard');
        enqueueSnackbar('Switched Business' + businessId, {
          variant: 'success'
        });
      }
    } catch (error) {
      enqueueSnackbar('Unable to Switch', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <BusinessIcon fontSize="medium" color="contrastText" />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${account.user.activeBusinessId} - ${account.user.activeRole}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {businessList.map(business => (
          <MenuItem onClick={handleSwitchBusiness(business.id)}>
            {business.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

Business.propTypes = {
  user: PropTypes.object.isRequired
};

export default Business;
