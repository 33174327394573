import mgmtInvoiceService from 'src/services/mgmtInvoiceService';

import { Invoice } from 'src/interface/invoiceIf';

export const INVOICE_CREATE ='@invoice/create';
export const INVOICE_CREATE_SUCCESS ='@invoice/create-success';
export const INVOICE_CREATE_FAILURE ='@invoice/create-failure';

export const INVOICE_UPDATE = '@invoice/update';
export const INVOICE_UPDATE_SUCCESS = '@invoice/update-success';
export const INVOICE_UPDATE_FAILURE = '@invoice/update-failure';


export function createInvoice(businessId, userId, order) {
  return async (dispatch) => {
    //console.log("createInvoice():", businessId, userId, order );
    let newInvoice = new Invoice(businessId, userId, order);
    newInvoice.businessId = businessId;
    newInvoice.addedBy = userId;

    //console.log("createInvoice():-->",  newInvoice);

    try {
      dispatch({ type: INVOICE_CREATE });

      const invoiceInfo = await mgmtInvoiceService.createInvoice(newInvoice);

      //console.log("createInvoice()(after):" + JSON.stringify(invoiceInfo));
      dispatch({
        type: INVOICE_CREATE_SUCCESS,
        payload: {
          invoiceInfo
        }
      });
    } catch (error) {
      dispatch({ type: INVOICE_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateInvoice(businessId, email, userId, invoiceInfo) {
  return async (dispatch) => {
    //console.log("updateInvoice():", businessId, email, userId, invoiceInfo );

    // TODO: Check if the email id and invoiceInfo.email is different

    let newInvoice = new Invoice(businessId, userId, invoiceInfo);
    newInvoice.businessId = businessId;
    newInvoice.addedBy = userId;

    //console.log("createInvoice():" + JSON.stringify(invoiceInfo) + " " + JSON.stringify(newInvoice));

    try {
      dispatch({ type: INVOICE_UPDATE });

      const invoiceInfo = await mgmtInvoiceService.updateInvoice(newInvoice);

      //console.log("createInvoice()(after):" + JSON.stringify(invoiceInfo));
      dispatch({
        type: INVOICE_UPDATE_SUCCESS,
        payload: {
          invoiceInfo
        }
      });
    } catch (error) {
      dispatch({ type: INVOICE_UPDATE_FAILURE });
      throw error;
    }
  };
}
