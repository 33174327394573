
class Service {

  constructor(businessId, updatedBy, info) {
    //console.log('Service(): ' + JSON.stringify(info));

    this.businessId = businessId;
    this.businessName = businessId;

    this.serviceCode = info.serviceCode;
    this.serviceName = info.serviceName;
    this.description = info.description;
    this.sacCode = info.sacCode;

    this.uom = info.uom;

    this.includesTaxes = info.includesTaxes;
    this.isTaxable = info.isTaxable;
    this.price = info.price;
    this.salePrice = info.salePrice;

    this.cgst = info.cgst;
    this.sgst = info.sgst;
    this.igst = info.igst;
    this.cess = info.cess;

    this.updatedBy = updatedBy;
  }
}

export default { Service };
export { Service };
