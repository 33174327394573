import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import businessService from 'src/services/businessService';

import mgmtClassesService from 'src/services/mgmtClassesService';
import { setClassesData } from 'src/actions/mgmtClassesAction';

import mgmtServiceService from 'src/services/mgmtServiceService';
import { setServiceData } from 'src/actions/mgmtServicesAction';

import mgmtCustomersService from 'src/services/mgmtCustomerService';
import { setCustomerData } from 'src/actions/mgmtCustomersAction';


import { BusinessInfo, BusinessAccountInfo, BusinessTaxInfo, BusinessTnC, BusinessUserInfo, BusinessUserList, BusinessLogo } from 'src/interface/businessIf';
import { UserProfileUpdate, PasswordInfo } from 'src/interface/authIf';

import { USER_ACTION_BLOCK , USER_ACTION_REMOVE} from 'src/config';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_PROFILE_SUCCESS = '@account/update-profile-success';
export const UPDATE_PROFILE_FAILED = '@account/update-profile-failed';

export const SWITCH_BUSINESS = '@account/switch-business';
export const UPDATE_BUSINESS_ADDRESS = '@account/update-business-address';
export const UPDATE_BUSINESS_ADDRESS_SUCCESS = '@account/update-business-address-success';
export const UPDATE_BUSINESS_ADDRESS_FAILURE = '@account/update-business-address-failure';

export const ADD_BUSINESS_USER = '@account/add-business-user';
export const ADD_BUSINESS_USER_SUCCESS = '@account/add-business-user-success';
export const ADD_BUSINESS_USER_FAILURE = '@account/add-business-user-failure';

export const UPDATE_BUSINESS_USER = '@account/update-business-user';
export const UPDATE_BUSINESS_USER_SUCCESS = '@account/update-business-user-success';
export const UPDATE_BUSINESS_USER_FAILURE = '@account/update-business-user-failure';


export const REMOVE_BUSINESS_USER = '@account/remove-business-user';
export const REMOVE_BUSINESS_USER_SUCCESS = '@account/remove-business-user-success';
export const REMOVE_BUSINESS_USER_FAILURE = '@account/remove-business-user-failure';

export const BLOCK_BUSINESS_USER = '@account/block-business-user';
export const BLOCK_BUSINESS_USER_SUCCESS = '@account/block-business-user-success';
export const BLOCK_BUSINESS_USER_FAILURE = '@account/block-business-user-failure';

export const UPDATE_BUSINESS_LOGO = '@account/update-business-logo';
export const UPDATE_BUSINESS_LOGO_SUCCESS = '@account/update-business-logo-success';
export const UPDATE_BUSINESS_LOGO_FAILURE = '@account/update-business-logo-failure';

export const UPDATE_BUSINESS_TNC = '@account/update-business-tnc';
export const UPDATE_BUSINESS_TNC_SUCCESS = '@account/update-business-tnc-success';
export const UPDATE_BUSINESS_TNC_FAILURE = '@account/update-business-tnc-failure';


export const CHANGE_PASSWORD = '@account/change-password';
export const CHANGE_PASSWORD_SUCCESS = '@account/change-password-success';
export const CHANGE_PASSWORD_FAILED = '@account/change-password-failure';



export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      //console.log("AccountAction-Login |Before loginReq ");
      const user = await authService.loginWithEmailAndPassword(email, password);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });

      //console.log("AccountAction-Login |Before fetchClassInfo ");
      const classInfo = await mgmtClassesService.fetchClassInfo(user.activeBusinessId, user.email);
      await dispatch(setClassesData(classInfo));

      //console.log("AccountAction-Login |After fetchClassInfo " + JSON.stringify(classInfo));
      const services = await mgmtServiceService.fetchServiceInfo(user.activeBusinessId, user.email);
      await dispatch(setServiceData(services));

      const customers = await mgmtCustomersService.fetchCustomerInfo(user.activeBusinessId, user.email);
      await dispatch(setCustomerData(customers));


    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {

  const businessId = "";
  const email = "";

  let userInfo = new UserProfileUpdate(businessId, email, update);
  //console.log("updateProfile(Action):", userInfo);

  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PROFILE});
      //console.log("updateProfile(dispatch):", userInfo);
      const userData = await authService.updateProfile(userInfo);
      //console.log("updateProfile(Action->):", userData);
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: {userData}
      });
    }catch (error) {
      dispatch({
        type: UPDATE_PROFILE_FAILED,
        payload: {}
      });
      throw error;
    }
  }
}

export function changePassword(businessId, email, update) {
  let passInfo = new PasswordInfo(businessId, email, update);
  //console.log("changePassword(Action):", passInfo);

  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD});
      const passData = await authService.changePassword(passInfo);
      //console.log("changePassword(Action->):", passData);
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: { passData}
      });
    }catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        payload: {}
      });
      throw error;
    }
  }
}

export function updateProfileBusinessInfo(businessId, updatedBy, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BUSINESS_ADDRESS });

      let businessInfo = new BusinessInfo(businessId, updatedBy, values);
      businessInfo.businessId = businessId;
      businessInfo.updatedBy = updatedBy;

      //console.log("updateProfileBusinessInfo |Before Req " + businessId + " " + JSON.stringify(businessInfo));
      const businessInfoData = await businessService.updateBusinessInfo(businessInfo);
      //console.log("updateProfileBusinessInfo | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      businessInfo = businessInfoData;

      dispatch({
        type: UPDATE_BUSINESS_ADDRESS_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: UPDATE_BUSINESS_ADDRESS_FAILURE });
      throw error;
    }
  };
}


export function updateProfileBusinessInfoAccounts(businessId, updatedBy, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BUSINESS_ADDRESS });

      let businessAccountInfo = new BusinessAccountInfo(businessId, updatedBy, values);
      businessAccountInfo.businessId = businessId;
      businessAccountInfo.updatedBy = updatedBy;

      //console.log("updateProfileBusinessInfo |Before Req " + businessId + " " + JSON.stringify(businessInfo));
      const businessInfoData = await businessService.updateBusinessInfoAccounts(businessAccountInfo);
      //console.log("updateProfileBusinessInfo | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      const businessInfo = businessInfoData;

      dispatch({
        type: UPDATE_BUSINESS_ADDRESS_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: UPDATE_BUSINESS_ADDRESS_FAILURE });
      throw error;
    }
  };
}

export function updateProfileBusinessInfoTax(businessId, updatedBy, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BUSINESS_ADDRESS });

      let businessTaxInfo = new BusinessTaxInfo(businessId, updatedBy, values);
      businessTaxInfo.businessId = businessId;
      businessTaxInfo.updatedBy = updatedBy;

      //console.log("updateProfileBusinessInfo |Before Req " + businessId + " " + JSON.stringify(businessInfo));
      const businessInfoData = await businessService.updateBusinessInfoTax(businessTaxInfo);
      //console.log("updateProfileBusinessInfo | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      const businessInfo = businessInfoData;

      dispatch({
        type: UPDATE_BUSINESS_ADDRESS_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: UPDATE_BUSINESS_ADDRESS_FAILURE });
      throw error;
    }
  };
}

export function updateProfileBusinessTnC(businessId, updatedBy, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BUSINESS_TNC });

      let businessTnC = new BusinessTnC(businessId, updatedBy, values);
      businessTnC.businessId = businessId;
      businessTnC.updatedBy = updatedBy;

      //console.log("updateProfileBusinessInfo |Before Req " + businessId + " " + JSON.stringify(businessInfo));
      const businessInfoData = await businessService.updateBusinessTnC(businessTnC);
      //console.log("updateProfileBusinessInfo | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      const businessInfo = businessInfoData;

      dispatch({
        type: UPDATE_BUSINESS_TNC_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: UPDATE_BUSINESS_TNC_FAILURE });
      throw error;
    }
  };
}


export function addBusinessUser(businessId, updatedBy, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_BUSINESS_USER });

      let businessUser = new BusinessUserInfo(businessId, updatedBy, values);
      businessUser.businessId = businessId;
      businessUser.updatedBy = updatedBy;

      //console.log("updateProfileBusinessInfo |Before Req " + businessId + " " + JSON.stringify(businessInfo));
      const businessInfoData = await businessService.addBusinessUser(businessUser);
      //console.log("updateProfileBusinessInfo | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      const businessInfo = businessInfoData;

      dispatch({
        type: ADD_BUSINESS_USER_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: ADD_BUSINESS_USER_FAILURE });
      throw error;
    }
  };
}

export function updateBusinessUser(businessId, updatedBy, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BUSINESS_USER });

      let businessUser = new BusinessUserInfo(businessId, updatedBy, values);
      businessUser.businessId = businessId;
      businessUser.updatedBy = updatedBy;

      //console.log("updateProfileBusinessInfo |Before Req " + businessId + " " + JSON.stringify(businessInfo));
      const businessInfoData = await businessService.updateBusinessUser(businessUser);
      //console.log("updateProfileBusinessInfo | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      const businessInfo = businessInfoData;

      dispatch({
        type: UPDATE_BUSINESS_USER_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: UPDATE_BUSINESS_USER_FAILURE });
      throw error;
    }
  };
}

export function removeBusinessUser(businessId, updatedBy, userList) {
  return async (dispatch) => {
    try {
      dispatch({ type: REMOVE_BUSINESS_USER });

      let businessUser = new BusinessUserList(businessId, updatedBy, userList, USER_ACTION_REMOVE);
      businessUser.businessId = businessId;
      businessUser.updatedBy = updatedBy;

      //console.log("updateProfileBusinessInfo |Before Req " + businessId + " " + JSON.stringify(businessInfo));
      const businessInfoData = await businessService.removeBusinessUser(businessUser);
      //console.log("updateProfileBusinessInfo | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      const businessInfo = businessInfoData;

      dispatch({
        type: REMOVE_BUSINESS_USER_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: REMOVE_BUSINESS_USER_FAILURE });
      throw error;
    }
  };
}

export function blockBusinessUser(businessId, updatedBy, userList) {
  return async (dispatch) => {
    try {
      dispatch({ type: BLOCK_BUSINESS_USER });

      let businessUser = new BusinessUserList(businessId, updatedBy, userList, USER_ACTION_BLOCK);
      businessUser.businessId = businessId;
      businessUser.updatedBy = updatedBy;

      //console.log("updateProfileBusinessInfo |Before Req " + businessId + " " + JSON.stringify(businessInfo));
      const businessInfoData = await businessService.blockBusinessUser(businessUser);
      //console.log("blockBusinessUser | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      const businessInfo = businessInfoData;

      dispatch({
        type: BLOCK_BUSINESS_USER_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: BLOCK_BUSINESS_USER_FAILURE });
      throw error;
    }
  };
}

export function updateBusinessLogo(businessId, updatedBy, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BUSINESS_LOGO });

      let businessLogo = new BusinessLogo(businessId, updatedBy, data);
      businessLogo.businessId = businessId;
      businessLogo.updatedBy = updatedBy;

      //console.log ("updateBusinessLogo businessLogo : ", businessLogo);

      //console.log("updateBusinessLogo | Data " + businessId + " " , data);
      const businessInfoData = await businessService.updateBusinessLogo(data);
      //console.log("updateBusinessLogo | Resp " + JSON.stringify(businessInfoData));

      // TODO: Add error if the businessId sent and received are not matching
      const businessInfo = businessInfoData;

      dispatch({
        type: UPDATE_BUSINESS_LOGO_SUCCESS,
        payload: {
          businessInfo
        }
      });
    } catch (error) {
      dispatch({ type: UPDATE_BUSINESS_LOGO_FAILURE });
      throw error;
    }
  };
}



export function switchBusiness(userId, businessId, newBusinessId) {
  return async (dispatch) => {
  //console.log("Action: switchBusiness ", userId, businessId, newBusinessId);
  const response = await axios.post('/api/user/switch-business', {email: userId, businessId: businessId, newBusinessId: newBusinessId});

  //console.log("Action: switchBusiness (post) ",response);

  dispatch({
      type: SWITCH_BUSINESS,
      payload: { activeBusinessId: response.data.payload }
    });
  };
}
