import {
  Activity as ActivityIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
} from "react-feather";

import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

const AdminNavConfig = [
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/app/admin/dashboard",
      },
/*      {
        title: "Dashboard Financial",
        icon: BarChartIcon,
        href: "/app/dashboard/dashboard-financial",
      }, */
    ],
  },
  {
    subheader: "Management",
    items: [
      {
        title: "Workshop",
        icon: GroupWorkIcon,
        href: "/app/admin/workshop",
        items: [
          {
            title: "Workshops",
            href: "/app/admin/workshops",
          },
          {
            title: "Create Workshop",
            href: "/app/admin/workshops/create",
          },
          {
            title: "Enroll Coachee",
            href: "/app/admin/workshops/enrollcoachee",
          },
          {
            title: "Plan MicroActions",
            href: "/app/admin/workshops/planmicrosactions",
          },

        ],
      },
      {
        title: "Coachee",
        icon: UsersIcon,
        href: "/app/admin/coachee",
        items: [
          {
            title: "Coachees",
            href: "/app/admin/coachees",
          },
          {
            title: "Register Coachee",
            href: "/app/admin/coachees/register",
          },
        ],
      },
      {
        title: "Coach",
        icon: LocalLibraryIcon,
        href: "/app/admin/coach",
        items: [
          {
            title: "Coaches",
            href: "/app/admin/coaches",
          },
          {
            title: "Register Coach",
            href: "/app/admin/coaches/register",
          },
        ],
      },
      {
        title: "Action",
        icon: ActivityIcon,
        href: "/app/admin/microaction",
        items: [
          {
            title: "Actions",
            href: "/app/admin/microactions",
          },
          {
            title: "Create MicroAction",
            href: "/app/admin/microactions/create",
          },
        ],
      },

    ],
  },
];

export default AdminNavConfig;
