import mgmtCustomerService from 'src/services/mgmtCustomerService';

import { Customer } from 'src/interface/customerIf';

export const CUSTOMER_INFO ='@customer/info';
export const CUSTOMER_CREATE ='@customer/create';
export const CUSTOMER_CREATE_SUCCESS ='@customer/create-success';
export const CUSTOMER_CREATE_FAILURE ='@customer/create-failure';

export const CUSTOMER_UPDATE = '@customer/update';
export const CUSTOMER_UPDATE_SUCCESS = '@customer/update-success';
export const CUSTOMER_UPDATE_FAILURE = '@customer/update-failure';


export function createCustomer(businessId, userId, customerInfo) {
  return async (dispatch) => {
    //console.log("createCustomer():", businessId, userId, customerInfo );
    let newCustomer = new Customer(businessId, userId, customerInfo);
    newCustomer.businessId = businessId;
    newCustomer.addedBy = userId;

    //console.log("createCustomer():" + JSON.stringify(customerInfo) + " " + JSON.stringify(newCustomer));

    try {
      dispatch({ type: CUSTOMER_CREATE });

      const customer = await mgmtCustomerService.createCustomer(newCustomer);

      //console.log("createCustomer()(after):" + JSON.stringify(customerInfo));
      dispatch({
        type: CUSTOMER_CREATE_SUCCESS,
        payload: {
          customer
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMER_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateCustomer(businessId, email, userId, customerInfo) {
  return async (dispatch) => {
    //console.log("updateCustomer():", businessId, email, userId, customerInfo );

    // TODO: Check if the email id and customerInfo.email is different

    let newCustomer = new Customer(businessId, userId, customerInfo);
    newCustomer.businessId = businessId;
    newCustomer.addedBy = userId;

    //console.log("createCustomer():" + JSON.stringify(customerInfo) + " " + JSON.stringify(newCustomer));

    try {
      dispatch({ type: CUSTOMER_UPDATE });

      const customer = await mgmtCustomerService.updateCustomer(newCustomer);

      //console.log("createCustomer()(after):" + JSON.stringify(customerInfo));
      dispatch({
        type: CUSTOMER_UPDATE_SUCCESS,
        payload: {
          customer
        }
      });
    } catch (error) {
      dispatch({ type: CUSTOMER_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function setCustomerData(customers) {
  return (dispatch) => dispatch({
    type: CUSTOMER_INFO,
    payload: {
      customers
    }
  });
}
