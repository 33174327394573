import React from 'react';
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
//import FeaturesClasses from './FeaturesClasses';
import FeaturesServices from './FeaturesServices';
import Testimonials from './Testimonials';
import CTA from './CTA';
import FAQS from './FAQS';
import Footer from './Footer';
import Modules from './Modules';
import ContactUs from './ContactUs';
import ContactInfo from './ContactInfo';

const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Khata Corner | Home">
      <Hero />
      <Modules />
      {/*<FeaturesServices />  */}
      <Divider />
      <Container maxWidth="lg">
        <Box mt={10} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6} alignCenter>
            <ContactInfo />
          </Grid>
          <Grid item xs={12} md={6} lg={6} alignCenter>
            <ContactUs />
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </Page>
  );
}

/*

<!--FeaturesClasses / -->

<Testimonials />
<CTA />
<FAQS />
*/

export default HomeView;
