
class Order {

  constructor(businessId, addedBy, info, order) {
    //console.log('Order(): ' + JSON.stringify(info), order);

    this.businessId = businessId;
    this.businessName = businessId;

    this.orderNo = '' ; //info.orderNo;
    this.orderDate = info.orderDate;
    this.billTo = {
      name: info.billToName,
      address: {
        address1: info.billToAddress1,
        address2: info.billToAddress2,
        city: info.billToCity,
        state: info.billToState,
        country: info.billToCountry,
        pincode: info.billToPincode,
      },
      email: info.billToEmail,
      contact: info.billToContact,
      statecode: info.billToStateCode,
      gstin: info.billToGSTIN,
      pan: info.billToPAN
    };

    this.shipTo = {
      name: info.shipToName,
      address: {
        address1: info.shipToAddress1,
        address2: info.shipToAddress2,
        city: info.shipToCity,
        state: info.shipToState,
        country: info.shipToCountry,
        pincode: info.shipToPincode,
      },
      email: info.shipToEmail,
      contact: info.shipToContact,
      statecode: info.shipToStateCode,
      gstin: info.shipToGSTIN,
      pan: info.shipToPAN
    };

    this.items = order.items;

    this.summary = {
      totalBeforeTax : order.summary.totalBeforeTax,
      totalCGST: order.summary.totalCGST,
      totalSGST: order.summary.totalSGST,
      totalIGST: order.summary.totalIGST,
      totalcess: order.summary.totalcess,
      totalTax: order.summary.totalTax,
      total: order.summary.total,
    }

    this.authorizedBy = {
      name: info.authorizedByName,
      designation: info.designation
    };

    this.bankDetails = {
      bankac: info.bankac,
      ifsc: info.bankifsc
    };

    this.status = info.status; // "created";  //info.status;
    this.payment = {
      status: info.paymentReceived,
      type: info.paymentType,
      details: info.paymentDetails,
    }

    this.comments = info.comments;
    this.refPO = info.refPO;

    this.addedBy = addedBy;
  }
}

class OrderStatus {

  constructor(businessId, addedBy, info) {
    //console.log('OrderStatus(): ' + JSON.stringify(info));

    this.businessId = businessId;
    this.businessName = businessId;

    this.orderId = info.orderId;
    this.status = info.status;
    this.statusDate = info.statusDate;
    this.comment = info.comment;

    this.addedBy = addedBy;

  }
}

class SplitOrder {
  constructor(businessId, addedBy, order, splitOrder, splitItems) {
    this.businessId = businessId;
    this.businessName = businessId;

    this.order = order;
    this.splitOrder = splitOrder;
    this.splitOrder.items = splitItems;

    this.addedBy = addedBy;
  }

}


export default { Order };
export { Order, OrderStatus, SplitOrder };
