import contactService from "src/services/contactService";

import { ContactUs } from "src/interface/contactUsIf";

export const CONTACT_US = "@contact-us/";
export const CONTACT_US_SUCCESS = "@contact-us/success";
export const CONTACT_US_FAILURE = "@contact-us/failure";


export function contactUs(info) {
  return async (dispatch) => {
    //console.log("contactActions:", info);
    const newContactUs = new ContactUs(info);

    //console.log("contactActions.contactUs:", info, newContactUs);

    try {
      dispatch({ type: CONTACT_US});

      const item = await contactService.contactUs(newContactUs);
      //console.log("contactActions: contactUs Resp:", item);

      dispatch({
        type: CONTACT_US_SUCCESS,
        payload: {
          item
        },
      });
    } catch (error) {
      //console.log("contactActions: contactUs: Error ", error);
      dispatch({ type: CONTACT_US_FAILURE });
      throw error;
    }
  };
}
