/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SERVICES_INFO,
  SERVICE_CREATE,
  SERVICE_CREATE_SUCCESS,
  SERVICE_CREATE_FAILURE,
  SERVICE_UPDATE,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAILURE
} from 'src/actions/mgmtServicesAction';

const initialState = {
  serviceInfo: {
    services: []
  }
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICES_INFO: {
      //console.log('servicesReducer: Payload ' + JSON.stringify(action.payload));

      const { services } = action.payload;

      //console.log("servicesReducer: services --> " + JSON.stringify(services) );

      return produce(state, draft => {
        draft.serviceInfo.services = services;
      });
    }

    // TODO: This clean may not be required here
    case SERVICE_CREATE: {
      return produce(state, draft => {
        // Ensure we clear current session
        //    draft.user = null;
      });
    }

    case SERVICE_CREATE_SUCCESS: {
      //console.log("mgmtClassesReducer: Payload " + JSON.stringify(action.payload));

      const { service } = action.payload;

      //console.log("mgmtClassesReducer: classesInfo" + JSON.stringify(classesInfo) );

      // TODO: SP check this should just added the record to services
      return produce(state, draft => {
        draft.serviceInfo.services.push(service);
      });
    }

    case SERVICE_CREATE_FAILURE: {
      return produce(state, () => {
        // Maybe store error
        //console.log("Todo: Failure needs to be handled");
      });
    }

    default: {
      return state;
    }
  }
};

export default servicesReducer;
