/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CLASSES_CLASS_INFO,
  CLASSES_CLASS_CREATE,
  CLASSES_CLASS_CREATE_SUCCESS,
  CLASSES_CLASS_CREATE_FAILURE,
  CLASSES_CLASS_UPDATE,
  CLASSES_CLASS_UPDATE_SUCCESS,
  CLASSES_CLASS_UPDATE_FAILURE,
  CLASSES_TEACHER_REGISTER,
  CLASSES_TEACHER_REGISTER_SUCCESS,
  CLASSES_TEACHER_REGISTER_FAILURE,
  CLASSES_TEACHER_UPDATE,
  CLASSES_TEACHER_UPDATE_SUCCESS,
  CLASSES_TEACHER_UPDATE_FAILURE,

  CLASSES_STUDENT_REGISTER,
  CLASSES_STUDENT_REGISTER_SUCCESS,
  CLASSES_STUDENT_REGISTER_FAILURE,
  CLASSES_STUDENT_UPDATE,
  CLASSES_STUDENT_UPDATE_SUCCESS,
  CLASSES_STUDENT_UPDATE_FAILURE,
  CLASSES_BATCH_REGISTER,
  CLASSES_BATCH_REGISTER_SUCCESS,
  CLASSES_BATCH_REGISTER_FAILURE,
  CLASSES_BATCH_UPDATE_SUCCESS,
  CLASSES_ENROLL_STUDENTS,
  CLASSES_ENROLL_STUDENTS_SUCCESS,
  CLASSES_ENROLL_STUDENTS_FAILURE,

  CLASSES_FEES_COLLECT,
  CLASSES_FEES_COLLECT_SUCCESS,
  CLASSES_FEES_COLLECT_FAILURE,
  CLASSES_FEES_UPDATE_RECEIPT,
  CLASSES_FEES_UPDATE_RECEIPT_SUCCESS,
  CLASSES_FEES_UPDATE_RECEIPT_FAILURE



} from 'src/actions/mgmtClassesAction';

const initialState = {
  classesInfo: {
    classList: [],
    teachers: [],
    students: [],
    batches: []
  }
};

const mgmtClassesReducer = (state = initialState, action) => {
  switch (action.type) {

    case CLASSES_CLASS_INFO: {

      //console.log("mgmtClassesReducer: Payload " + JSON.stringify(action.payload));

      const { classesInfo } = action.payload;

      //console.log("mgmtClassesReducer: classesInfo" + JSON.stringify(classesInfo) );

      return produce(state, (draft) => {
        draft.classesInfo = classesInfo;
      });
    }




    // TODO: This clean may not be required here
    case CLASSES_CLASS_CREATE: {
      return produce(state, (draft) => {
        // Ensure we clear current session
    //    draft.user = null;
      });
    }

    case CLASSES_CLASS_CREATE_SUCCESS:
    case CLASSES_CLASS_UPDATE_SUCCESS: {

      //console.log("mgmtClassesReducer: Payload " + JSON.stringify(action.payload));

      const { classesInfo } = action.payload;

      //console.log("mgmtClassesReducer: classesInfo" + JSON.stringify(classesInfo) );

      return produce(state, (draft) => {
        draft.classesInfo = classesInfo;
      });
    }

    case CLASSES_CLASS_CREATE_FAILURE:
    case CLASSES_CLASS_UPDATE_FAILURE: {
      return produce(state, () => {
        // Maybe store error
        //console.log("Todo: Failure needs to be handled");
      });
    }

    case CLASSES_TEACHER_REGISTER_SUCCESS:
    case CLASSES_TEACHER_UPDATE_SUCCESS: {

      //console.log("mgmtClassesReducer: TeacherSuccess Payload " + JSON.stringify(action.payload));

      const { classesInfo } = action.payload;

      //console.log("mgmtClassesReducer: TeacherSuccess classesInfo" + JSON.stringify(classesInfo) );

      return produce(state, (draft) => {
        draft.classesInfo = classesInfo;
      });
    }

    case CLASSES_TEACHER_REGISTER_FAILURE: {
      return produce(state, () => {
        // Maybe store error
        //console.log("Todo: Failure needs to be handled");
      });
    }

    case CLASSES_STUDENT_REGISTER_SUCCESS:
    case CLASSES_STUDENT_UPDATE_SUCCESS: {

      //console.log("mgmtClassesReducer: StudentSuccess Payload " + JSON.stringify(action.payload));

      const { classesInfo } = action.payload;

      //console.log("mgmtClassesReducer: StudentSuccess classesInfo" + JSON.stringify(classesInfo) );

      return produce(state, (draft) => {
        draft.classesInfo = classesInfo;
      });
    }

    case CLASSES_STUDENT_REGISTER_FAILURE:
    case CLASSES_STUDENT_UPDATE_FAILURE: {
      return produce(state, () => {
        // Maybe store error
        //console.log("Todo: Failure needs to be handled");
      });
    }

    case CLASSES_BATCH_REGISTER_SUCCESS:
    case CLASSES_BATCH_UPDATE_SUCCESS:  {
      //console.log("mgmtClassesReducer: Batch Payload " + JSON.stringify(action.payload));

      const { classesInfo } = action.payload;

      //console.log("mgmtClassesReducer: Batch classesInfo" + JSON.stringify(classesInfo) );

      return produce(state, (draft) => {
        draft.classesInfo = classesInfo;
      });
    }


    case CLASSES_BATCH_REGISTER_FAILURE: {
      return produce(state, () => {
        // Maybe store error
        //console.log("Todo: Failure needs to be handled");
      });
    }

    case CLASSES_ENROLL_STUDENTS_SUCCESS: {

      //console.log("mgmtClassesReducer: Batch Payload " + JSON.stringify(action.payload));

      const { classesInfo } = action.payload;

      //console.log("mgmtClassesReducer: Batch classesInfo" + JSON.stringify(classesInfo) );

      return produce(state, (draft) => {
        draft.classesInfo = classesInfo;
      });
    }

    case CLASSES_FEES_COLLECT_SUCCESS:
    case CLASSES_FEES_UPDATE_RECEIPT_SUCCESS: {

      //console.log("mgmtClassesReducer: Batch Payload " + JSON.stringify(action.payload));

      //const { classesInfo } = action.payload;

      //console.log("mgmtClassesReducer: Batch classesInfo" + JSON.stringify(classesInfo) );

      //return produce(state, (draft) => {
//        draft.classesInfo = classesInfo;
  //    });
    }


    default: {
      return state;
    }
  }
};

export default mgmtClassesReducer;
