import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg" >
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
              //color="#AFDBF5"
              //color="contrastText"
            >
              <Typography variant="overline" color="blue">
                Introducing
              </Typography>
              <Typography variant="h1" color="contrastText">
                Khata Corner
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="inherit">
                  One Stop Solution that will enable you to keep all the accounting and bookkeeping in one place for Your Business.
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography variant="h1" color="textPrimary">
                      1
                    </Typography>
                    <Typography variant="overline">
                      Solution
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="textPrimary">
                      Online
                    </Typography>
                    <Typography variant="overline">
                      Book Keeping
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              <div className={classes.shape}>
                <img alt="Shapes" src="/static/home/shapes.svg" />
              </div>
              <div className={classes.image}>
                <img alt="Presentation" src="/static/home/khatacorner.png" />
              </div>
            </Box>
          </Grid>
          </Grid>
          </Container>


    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
