import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Box as ProductIcon
} from 'react-feather';

import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';

import { BUSINESS_TYPES, USER_ROLES } from 'src/constants';

const UserNavConfig = [
  {
    subheader: 'Reports',
    businesstype: [
      BUSINESS_TYPES.GENERAL,
      BUSINESS_TYPES.CLASSES,
      BUSINESS_TYPES.CONSULTANT,
      BUSINESS_TYPES.SERVICES,
      BUSINESS_TYPES.MANUFACTURING,
    ],
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/dashboards/dashboard',
        role: [
          USER_ROLES.OWNER, USER_ROLES.MANAGER
        ],
      },
      // {
      //   title: 'Dashboard Financial',
      //   icon: BarChartIcon,
      //   href: '/app/dashboards/dashboard-financial',
      //   role: [
      //     USER_ROLES.OWNER
      //   ],
      // },
      // {
      //   title: 'Dashboard Classes',
      //   icon: BarChartIcon,
      //   businesstype: [
      //     BUSINESS_TYPES.CLASSES,
      //   ],
      //   href: '/app/dashboards/dashboard-classes',
      //   role: [
      //     USER_ROLES.OWNER, USER_ROLES.MANAGER
      //   ],
      // },
     /*{
        title: 'Dashboard Alternate',
        icon: BarChartIcon,
        href: '/app/dashboards/dashboard-alternate',
        role: [
          USER_ROLES.OWNER, USER_ROLES.MANAGER
        ],
      } */
    ]
  },
  {
    subheader: 'HR Management',
    businesstype: [
      // BUSINESS_TYPES.GENERAL,
      // BUSINESS_TYPES.CLASSES,
      // BUSINESS_TYPES.CONSULTANT,
      // BUSINESS_TYPES.SERVICES,
      // BUSINESS_TYPES.MANUFACTURING
    ],
    items: [
      {
        title: 'Employee Info',
        icon: UsersIcon,
        href: '/app/hr/employee',
        items: [
          {
            title: 'View Employees',
            href: '/app/hr/employee/employees',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'New Employee',
            href: '/app/hr/employee/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Payroll',
        icon: ShoppingCartIcon,
        href: '/app/hr/payroll',
        items: [
          {
            title: 'View Payroll',
            href: '/app/hr/payroll/list',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Generate Payroll',
            href: '/app/hr/payroll/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      }
    ]
  },
  {
    subheader: 'Accounts',
    businesstype: [
      BUSINESS_TYPES.GENERAL,
      BUSINESS_TYPES.CLASSES,
      BUSINESS_TYPES.CONSULTANT,
      BUSINESS_TYPES.SERVICES,
      BUSINESS_TYPES.MANUFACTURING
    ],
    items: [
      {
        title: 'Sales',
        icon: UsersIcon,
        href: '/app/accounts/sales',
        items: [
          {
            title: 'View Receipts',
            href: '/app/accounts/sales/receipts',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Receipt',
            href: '/app/accounts/sales/receipts/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Purchase',
        icon: ShoppingCartIcon,
        href: '/app/accounts/purchase',
        items: [
          {
            title: 'View Receipts',
            href: '/app/accounts/purchase/receipts',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Receipt',
            href: '/app/accounts/purchase/receipts/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      }
    ]
  },
  {
    subheader: 'Classes Management',
    businesstype: [BUSINESS_TYPES.CLASSES],
    items: [
      {
        title: 'Classes',
        icon: UsersIcon,
        href: '/app/management/classes',
        items: [
          {
            title: 'Classes',
            href: '/app/management/classes',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Classes',
            href: '/app/management/classes/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Register Teacher',
            href: '/app/management/classes/teacher/register',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],

          },
          {
            title: 'Register Student',
            href: '/app/management/classes/student/register',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Batches',
            href: '/app/management/classes/batch/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          /*{
            title: 'Assign Teachers',
            href: '/app/management/classes/batch/assign'
          }, */
          {
            title: 'Enroll Students',
            href: '/app/management/classes/batch/enroll',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER, USER_ROLES.ACCOUNTANT
            ],
          },
          {
            title: 'Log Attendence',
            href: '/app/management/classes/batch/attendance',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER, USER_ROLES.TEACHER
            ],
          },
          {
            title: 'View Attendence',
            href: '/app/management/classes/attendance',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER, USER_ROLES.TEACHER
            ],
          },
          {
            title: 'Fees Collection',
            href: '/app/management/classes/fees/collect',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER, USER_ROLES.ACCOUNTANT
            ],
          },
          {
            title: 'View Fees',
            href: '/app/management/classes/fees',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER, USER_ROLES.ACCOUNTANT
            ],
          }
        ]
      }
    ]
  },
  {
    subheader: 'Management',
    businesstype: [BUSINESS_TYPES.SERVICES, BUSINESS_TYPES.CONSULTANT],
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Customer',
            href: '/app/management/customers/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Services',
        icon: ShoppingCartIcon,
        href: '/app/management/services',
        items: [
          {
            title: 'List Services',
            href: '/app/management/services',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Service',
            href: '/app/management/services/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Order',
            href: '/app/management/orders/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      }
    ]
  },
  {
    subheader: 'Management',
    businesstype: [BUSINESS_TYPES.MANUFACTURING],
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Customer',
            href: '/app/management/customers/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Products',
        icon: ProductIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Services',
        icon: ShoppingCartIcon,
        href: '/app/management/services',
        items: [
          {
            title: 'List Services',
            href: '/app/management/services',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Service',
            href: '/app/management/services/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'Create Order',
            href: '/app/management/orders/create',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1',
            role: [
              USER_ROLES.OWNER, USER_ROLES.MANAGER
            ],
          }
        ]
      }
    ]
  }
];

export default UserNavConfig;

/*    {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders'
          },
          {
            title: 'View Order',
            href: '/app/management/orders/1'
          }
        ]
      },
*/

/*{
    subheader: 'Applications',
    items: [
      {
        title: 'Projects Platform',
        href: '/app/projects',
        icon: BriefcaseIcon,
        items: [
          {
            title: 'Overview',
            href: '/app/projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/projects/create'
          },
          {
            title: 'View Project',
            href: '/app/projects/1'
          }
        ]
      },
      {
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      },
      {
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      },
      {
        title: 'Chat',
        href: '/app/chat',
        icon: MessageCircleIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon
      },
    ]
  },
  {
    subheader: 'Auth',
    items: [
      {
        title: 'Login',
        href: '/login-unprotected',
        icon: LockIcon
      },
      {
        title: 'Register',
        href: '/register-unprotected',
        icon: UserPlusIcon
      },
      {
        title: 'Login: Guest Protected',
        href: '/login',
        icon: ShieldIcon
      },
      {
        title: 'Register: Guest Protected',
        href: '/register',
        icon: ShieldIcon
      }
    ]
  },
  {
    subheader: 'Pages',
    href: '/app/pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  {
    subheader: 'Extra',
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          },
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
    ]
  } */
