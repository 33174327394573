import mgmtServiceService from 'src/services/mgmtServiceService';

import { Service } from 'src/interface/servicesIf';


export const SERVICES_INFO ='@service/info';
export const SERVICE_CREATE ='@service/create';
export const SERVICE_CREATE_SUCCESS ='@service/create-success';
export const SERVICE_CREATE_FAILURE ='@service/create-failure';

export const SERVICE_UPDATE = '@service/update';
export const SERVICE_UPDATE_SUCCESS = '@service/update-success';
export const SERVICE_UPDATE_FAILURE = '@service/update-failure';


export function createService(businessId, userId, serviceInfo) {
  return async (dispatch) => {
    //console.log("createService():", businessId, userId, serviceInfo );
    let newService = new Service(businessId, userId, serviceInfo);
    newService.businessId = businessId;
    newService.addedBy = userId;

    //console.log("createService():" + JSON.stringify(serviceInfo) + " " + JSON.stringify(newService));

    try {
      dispatch({ type: SERVICE_CREATE });

      const service = await mgmtServiceService.createService(newService);

      //console.log("createService()(after):" + JSON.stringify(service));
      dispatch({
        type: SERVICE_CREATE_SUCCESS,
        payload: {
          service
        }
      });
    } catch (error) {
      dispatch({ type: SERVICE_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateService(businessId, userId, serviceCode, serviceInfo) {
  return async (dispatch) => {
    //console.log("updateService():", businessId, userId, serviceCode, serviceInfo );

    // TODO: Check if the email id and serviceInfo.serviceCode is different

    let newService = new Service(businessId, userId, serviceInfo);
    newService.businessId = businessId;
    newService.addedBy = userId;

    //console.log("createService():" + JSON.stringify(serviceInfo) + " " + JSON.stringify(newService));

    try {
      dispatch({ type: SERVICE_UPDATE });

      const service = await mgmtServiceService.updateService(newService);

      //console.log("createService()(after):" + JSON.stringify(service));
      dispatch({
        type: SERVICE_UPDATE_SUCCESS,
        payload: {
          service
        }
      });
    } catch (error) {
      dispatch({ type: SERVICE_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function setServiceData(services) {
  return (dispatch) => dispatch({
    type: SERVICES_INFO,
    payload: {
      services
    }
  });
}
