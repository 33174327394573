import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';

import { FEATURES_SERVICES } from './FeatureContent';
import FeatureCard from './FeatureCard';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function FeaturesServices({ className, ...rest }) {
  const classes = useStyles();

  const cardInfo = FEATURES_SERVICES;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h2" color="secondary" align="center">
          Accounting for Services
        </Typography>
        <Typography
          component="p"
          variant="overline"
          align="center"
          color="textPrimary"
        >
          Elements of Customer, Order and Invoices for Services
        </Typography>
        <Box mt={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FeatureCard cardInfo={cardInfo[0]} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard cardInfo={cardInfo[1]} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard cardInfo={cardInfo[2]} />
            </Grid>
          </Grid>
        </Box>
        <Box mt={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FeatureCard cardInfo={cardInfo[3]} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard cardInfo={cardInfo[4]} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureCard cardInfo={cardInfo[5]} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

FeaturesServices.propTypes = {
  className: PropTypes.string
};

export default FeaturesServices;
