import { BUSINESS_TYPES, USER_ROLES } from 'src/constants';

export function hasAccess(businessType, role, feature) {
  //console.log("HasAccess() ", businessType, role, feature);
  let hasAccessFlag = false;

  switch (businessType) {
    case "General":
      switch (feature) {
        case 'BusinessSetting':
          if (role === USER_ROLES.OWNER || role === USER_ROLES.MANAGER) {
            hasAccessFlag = true;
          }
          break;

        default:
          break;
      }
      break;

    case BUSINESS_TYPES.CLASSES:
      break;

    case BUSINESS_TYPES.MANUFACTURING:
      break;

    default:
      break;
  }

  return hasAccessFlag;
}
