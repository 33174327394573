import axios from 'src/utils/axios';


class MgmtOrderService {

  createOrder = (newOrder) => new Promise((resolve, reject) => {

    const payload = newOrder;

    //console.log("MgmtOrderService.createOrder:" + JSON.stringify(payload));

    axios.post('/api/accounts/order/create', {payload})
    .then((response) => {
      //console.log("MgmtOrderesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let order = response.data.payload;
        resolve(order);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtOrderService.createOrder (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateOrder = (editOrder) => new Promise((resolve, reject) => {

    const payload = editOrder;

    //console.log("MgmtOrderService.updateOrder:" + JSON.stringify(payload));

    axios.post('/api/accounts/order/update', {payload})
    .then((response) => {
      //console.log("MgmtOrderesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let order = response.data.payload;
        resolve(order);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtOrderService.createOrder (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateOrderStatus = (orderStatus) => new Promise((resolve, reject) => {

    const payload = orderStatus;

    //console.log("MgmtOrderService.updateOrderStatus:" + JSON.stringify(payload));

    axios.post('/api/accounts/order/update/status', {payload})
    .then((response) => {
      //console.log("MgmtOrderesService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let order = response.data.payload;
        resolve(order);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtOrderService.updateOrderStatus (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })


  createSplitOrder = (splitOrder) => new Promise((resolve, reject) => {

    const payload = splitOrder;

    console.log("MgmtOrderService.createSplitOrder:" + JSON.stringify(payload));

    axios.post('/api/accounts/order/splitorder', {payload})
    .then((response) => {
      console.log("MgmtOrderesService(Response).createSplitOrder:" + JSON.stringify(response));

      if(response.data.payload){
        let order = response.data.payload;
        resolve(order);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      console.log("MgmtOrderService.createSplitOrder (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

}



const mgmtOrderService = new MgmtOrderService();

export default mgmtOrderService;
