
class Customer {

  constructor(businessId, updatedBy, info) {
    //console.log('Customer(): ' + JSON.stringify(info));

    this.businessId = businessId;
    this.businessName = businessId;

    this.email = info.email;
    this.name = info.fullName;
    this.mobile = info.mobile;
    this.address = {
      address1: info.address1,
      address2: info.address2,
      city: info.city,
      state: info.state,
      country: info.country,
      pincode: info.pincode
    };
    this.gstin = info.gstin;
    this.pan = info.pan;
    this.businessName = info.businessName;
    this.active = info.active;

    this.contacts = info.contacts;
    this.updatedBy = updatedBy;
  }
}


export default { Customer };
export { Customer };
