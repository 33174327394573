// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = '/api';
export const ENABLE_REDUX_LOGGER = false;

export const STATE_OPTIONS = [
  'Andra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Orissa',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telagana',
  'Tripura',
  'Uttaranchal',
  'Uttar Pradesh',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadar and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Lakshadeep',
  'Pondicherry'
];

export const GST_STATE_OPTIONS = [
  { statecode: '35', statename: 'Andaman & Nicobar Islands' },
  { statecode: '37', statename: 'Andhra Pradesh (New)' },
  { statecode: '28', statename: 'Andhra Pradesh (Old)' },
  { statecode: '12', statename: 'Arunachal Pradesh' },
  { statecode: '18', statename: 'Assam' },
  { statecode: '10', statename: 'Bihar' },
  { statecode: '4', statename: 'Chandigarh' },
  { statecode: '22', statename: 'Chhattisgarh' },
  { statecode: '26', statename: 'Dadra & Nagar Haveli' },
  { statecode: '25', statename: 'Daman & Diu' },
  { statecode: '7', statename: 'Delhi' },
  { statecode: '30', statename: 'Goa' },
  { statecode: '24', statename: 'Gujarat' },
  { statecode: '6', statename: 'Haryana' },
  { statecode: '2', statename: 'Himachal Pradesh' },
  { statecode: '1', statename: 'Jammu & Kashmir' },
  { statecode: '20', statename: 'Jharkhand' },
  { statecode: '29', statename: 'Karnataka' },
  { statecode: '32', statename: 'Kerala' },
  { statecode: '31', statename: 'Lakshadweep' },
  { statecode: '23', statename: 'Madhya Pradesh' },
  { statecode: '27', statename: 'Maharashtra' },
  { statecode: '14', statename: 'Manipur' },
  { statecode: '17', statename: 'Meghalaya' },
  { statecode: '15', statename: 'Mizoram' },
  { statecode: '13', statename: 'Nagaland' },
  { statecode: '21', statename: 'Orissa' },
  { statecode: '34', statename: 'Puducherry' },
  { statecode: '3', statename: 'Punjab' },
  { statecode: '8', statename: 'Rajasthan' },
  { statecode: '11', statename: 'Sikkim' },
  { statecode: '33', statename: 'Tamil Nadu' },
  { statecode: '36', statename: 'Telengana' },
  { statecode: '16', statename: 'Tripura' },
  { statecode: '9', statename: 'Uttar Pradesh' },
  { statecode: '5', statename: 'Uttarakhand' },
  { statecode: '19', statename: 'West Bengal' }
];

export const COUNTRY_OPTIONS = ['India'];

export const PAYMENT_TYPES = [
  { id: 'Cash', name: 'Cash' },
  { id: 'Card', name: 'Card' },
  { id: 'GPay', name: 'GPay' },
  { id: 'PhonePe', name: 'PhonePe' },
  { id: 'NEFT', name: 'NEFT' },
  { id: 'Others', name: 'Others' }
];


export const SERVICE_UOM = [
  { id: 'Qty', name: 'Qty' },
  { id: 'Hour', name: 'Hour' },
  { id: 'Day', name: 'Day' },
  { id: 'Month', name: 'Month' }
];

export const PRODUCT_UOM = [
  { id: 'Nos', name: 'Nos' },
  { id: 'Qty', name: 'Qty' },
  { id: 'Set', name: 'Set' },
  { id: 'Pieces', name: 'Pieces' },
  { id: 'Kg', name: 'Kg' },
  { id: 'Ltr', name: 'Ltr'}
];

export const ORDER_STATUS_CREATED = 'created';
export const ORDER_STATUS_INPROCESS = 'inprocess';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_INVOICED = 'invoiced';
export const ORDER_STATUS_ONHOLD = 'onhold';


export const ORDER_STATUS = [
  { id: ORDER_STATUS_CREATED, name: 'Created'},
  { id: ORDER_STATUS_INPROCESS, name: 'In Process'},
  { id: ORDER_STATUS_COMPLETED, name: 'Completed'},
  { id: ORDER_STATUS_INVOICED, name: 'Invoiced'},
  { id: ORDER_STATUS_ONHOLD, name: 'On Hold'}
]

export const INVOICE_STATUS_INVOICED = 'invoiced';
export const INVOICE_STATUS_PAID = 'paid';
export const INVOICE_STATUS_OVERDUE = 'overdue';

export const INVOICE_STATUS = [
  { id: INVOICE_STATUS_INVOICED, name: 'Invoiced'},
  { id: INVOICE_STATUS_PAID, name: 'Paid'},
  { id: INVOICE_STATUS_OVERDUE, name: 'Overdue'},
//  { id: INVOICE_STATUS_ONHOLD, name: 'On Hold'}
]

export const USER_ROLE_ACCOUNTANT = 'ACCOUNTANT';
export const USER_ROLE_AUDITOR = 'AUDITOR';
export const USER_ROLE_MANAGER = 'MANAGER';
export const USER_ROLE_TEACHER = 'TEACHER';
export const USER_ROLE_OWNER = 'OWNER';

export const USER_ROLES = [
  { id: USER_ROLE_OWNER, role: 'Owner'},
  { id: USER_ROLE_ACCOUNTANT, role: 'Accountant'},
  { id: USER_ROLE_AUDITOR, role: 'Auditor'},
  { id: USER_ROLE_MANAGER, role: 'Manager'},
  { id: USER_ROLE_TEACHER, role: 'Teacher'},
]


export const USER_STATE = {
    NEW: { label: 'New', color: 'warning'},
    ACTIVE: { label: 'Active', color: 'success'},
    INACTIVE: { label: 'In Active', color: 'warning'},
    BLOCKED: { label: 'Blocked', color: 'error'},
    REMOVED: { label: 'Removed', color: 'error'},
    DISABLE: { label: 'Disable', color: 'error'},
}

export const USER_ACTION_BLOCK = 'BLOCK';
export const USER_ACTION_REMOVE = 'REMOVE';

export const BLOOD_GROUP = [
  "Pending",
  "A+",
  "B+",
  "AB+",
  "O+",
  "A-",
  "B-",
  "AB-",
  "O-"
];

export const PAYROLL_SALARY_HEADER = [
  { field: 'id', headerName: 'Sr#', width: 100 },
  { field: 'type', headerName: 'Type', width: 150 },
  { field: 'component', headerName: 'Component', width: 200 },
  { field: 'category', headerName: 'Category', type: 'singleSelect', valueOptions: [ 'Earnings', 'Deductions'], editable: true, width: 150 },
  { field: 'applicable', headerName: 'Applicable', type: 'boolean', width: 150, editable: true },
  { field: 'rate', headerName: 'Rate (%)', width: 150, type: 'number', editable: true },
  { field: 'value', headerName: 'Absolute Value', type: 'number', width: 150, editable: true },
  { field: 'payable', headerName: 'Payable', type: 'singleSelect', valueOptions: [ 'Monthly', 'Yearly'], editable: true, width: 150 },

];


export const PAYROLL_SALARY_TEMPLATE = [
  {
    id: 1,
    type: 'Mandatory',
    component: 'Basic',
    category: 'Earnings',
    applicable: true,
    rate: undefined,
    value: undefined,
    payable: 'Monthly'
  },
  {
    id: 2,
    type: 'Mandatory',
    component: 'HRA',
    category: 'Earnings',
    applicable: true,
    rate: 50,
    value: undefined,
    payable: 'Monthly'
  },
  {
    id: 3,
    type: 'Mandatory',
    component: 'Conveyance',
    category: 'Deductions',
    applicable: true,
    rate: undefined,
    value: 1600,
    payable: 'Monthly'
  },
  {
    id: 4,
    type: 'Statutory',
    component: 'PF (employee)',
    category: 'Deductions',
    applicable: true,
    rate: 12.5,
    value: undefined,
    payable: 'Monthly'
  },
  {
    id: 5,
    type: 'Statutory',
    component: 'PF (employer)',
    category: 'Deductions',
    applicable: true,
    rate: 12.5,
    value: undefined,
    payable: 'Monthly'
  }
];



export default {};
