import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Toolbar,
  Hidden,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/config';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = event => {
    setAuth(event.target.checked);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Typography variant="caption" color="textSecondary">
          Khata Corner
        </Typography>
        <Box flexGrow={1} />
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/"
          underline="none"
          variant="body2"
        >
          Home
        </Link>
        <Link
          className={classes.link}
          color="textSecondary"
          onClick={handleMenu}
          underline="none"
          variant="body2"
        >
          Products
        </Link>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} component={RouterLink}
          to="/products/ServicesManagement">Service Management</MenuItem>
          <MenuItem onClick={handleClose} component={RouterLink}
          to="/products/ClassesManagement">Classes Management</MenuItem>
        </Menu>

        {/*<Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/pricing"
          underline="none"
          variant="body2"
        >
          Pricing
        </Link>
        */}
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app"
          underline="none"
          variant="body2"
        >
          Sign In
        </Link>
        <Divider className={classes.divider} />
        <Button
          color="secondary"
          component={RouterLink}
          to="/register-business"
          variant="contained"
          size="small"
        >
          Register Business
        </Button>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

/*  Add sections once ready in toolbar sections
<Link
  className={classes.link}
  color="textSecondary"
  component={RouterLink}
  to="/docs"
  underline="none"
  variant="body2"
>
  Documentation
</Link>
<Divider className={classes.divider} />
<Button
  color="secondary"
  component="a"
  href="https://material-ui.com/store/items/devias-kit-pro"
  variant="contained"
  size="small"
>
  Get the kit
</Button>

*/
