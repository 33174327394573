import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function AdminAuthGuard({ children }) {
  const account = useSelector((state) => state.account);

  //TODO SP : Ensure to fix this for admin login security
  /* if (!account.user) {
    return <Redirect to="/login" />;
  } */

  return children;
}

AdminAuthGuard.propTypes = {
  children: PropTypes.any
};

export default AdminAuthGuard;
