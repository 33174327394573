import jwtDecode from "jwt-decode";
import axios from "src/utils/axios";

class ContactService {

  contactUs = (newContactUs) =>
    new Promise((resolve, reject) => {
      axios
        .post("/api/contact/contactus", newContactUs)
        .then((response) => {
          //console.log("ContactService:contactUs", response.data);
          if (response.data) {
            const supply = response.data;
            //console.log("Response: ", response.data);

            resolve(supply);
          } else {
            //console.log("ContactService:contactUs error", response.data);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          //console.log("ContactService:contactUs error", error);
          reject(error);
        });
    });

}

const contactService = new ContactService();

export default contactService;
