import mgmtProductService from 'src/services/mgmtProductService';

import { Product } from 'src/interface/productsIf';


export const PRODUCTS_INFO ='@product/info';
export const PRODUCT_CREATE ='@product/create';
export const PRODUCT_CREATE_SUCCESS ='@product/create-success';
export const PRODUCT_CREATE_FAILURE ='@product/create-failure';

export const PRODUCT_UPDATE = '@product/update';
export const PRODUCT_UPDATE_SUCCESS = '@product/update-success';
export const PRODUCT_UPDATE_FAILURE = '@product/update-failure';


export function createProduct(businessId, userId, productInfo) {
  return async (dispatch) => {
    //console.log("createProduct():", businessId, userId, productInfo );
    let newProduct = new Product(businessId, userId, productInfo);
    newProduct.businessId = businessId;
    newProduct.addedBy = userId;

    //console.log("createProduct():" + JSON.stringify(productInfo) + " " + JSON.stringify(newProduct));

    try {
      dispatch({ type: PRODUCT_CREATE });

      const product = await mgmtProductService.createProduct(newProduct);

      //console.log("createProduct()(after):" + JSON.stringify(product));
      dispatch({
        type: PRODUCT_CREATE_SUCCESS,
        payload: {
          product
        }
      });
    } catch (error) {
      dispatch({ type: PRODUCT_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateProduct(businessId, userId, productCode, productInfo) {
  return async (dispatch) => {
    //console.log("updateProduct():", businessId, userId, productCode, productInfo );

    // TODO: Check if the email id and productInfo.productCode is different

    let newProduct = new Product(businessId, userId, productInfo);
    newProduct.businessId = businessId;
    newProduct.addedBy = userId;

    //console.log("createProduct():" + JSON.stringify(productInfo) + " " + JSON.stringify(newProduct));

    try {
      dispatch({ type: PRODUCT_UPDATE });

      const product = await mgmtProductService.updateProduct(newProduct);

      //console.log("createProduct()(after):" + JSON.stringify(product));
      dispatch({
        type: PRODUCT_UPDATE_SUCCESS,
        payload: {
          product
        }
      });
    } catch (error) {
      dispatch({ type: PRODUCT_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function setProductData(products) {
  return (dispatch) => dispatch({
    type: PRODUCTS_INFO,
    payload: {
      products
    }
  });
}
