import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';
import ModuleCard from './ModuleCard';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));



function Modules({ className, ...rest }) {
  const classes = useStyles();

  const cardInfo1 = {
    title: "Classes Management",
    image_src: "/static/home/modules/coaching_01.jpg",
    image_title: "Coaching",
    summary: "Easy to operate and stay focused on the core business of coaching be it sports, education, dance, etc",
    url: "products/ClassesManagement"
  };

  const cardInfo2 = {
    title: "Accounting for Services",
    image_src: "/static/home/modules/service_02.jpg",
    image_title: "Services",
    summary: "Define Services with rates, create & track order and automatically raise Invoices to keep track of orders  & financing from any where",
    url: "products/ServicesManagement"
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Khata Corner Brings to You
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Elements of Accounting with Operations and Book Keeping
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={6}
              alignCenter
            >
              <ModuleCard cardInfo={cardInfo1}/>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              alignCenter
            >
              <ModuleCard cardInfo={cardInfo2}/>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

Modules.propTypes = {
  className: PropTypes.string
};

export default Modules;
