import React from 'react';

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import RemoveIcon from '@material-ui/icons/Remove';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  header: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  avatar: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.contrastText,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  bullet: {
    fontSize: 10,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

}));

function FeatureCard({ cardInfo, className, ...rest }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header}
        avatar={
          <Avatar className={classes.avatar}>
            {cardInfo.avatarLabel}
          </Avatar>
        }
        title={cardInfo.title}
      />
      <CardContent>
      <Typography
        variant="body1"
        color="textPrimary"
        gutterBottom
      >

        <List>
        { cardInfo.features.map( feature => (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon  className={classes.bullet} />
            </ListItemIcon>
            <ListItemText>{feature}</ListItemText>
          </ListItem>
        )) }
        </List>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default  FeatureCard
