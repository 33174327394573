import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(theme => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    },
    '& dd': {
      marginTop: theme.spacing(2)
    }
  }
}));

function Footer({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3} component="dl">
          <Grid item xs={12} md={4}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Typography variant="h3" color="contrastText">
            Khata Corner
          </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
              <dd>
                <Typography variant="h5" color="contrastText">
                  About Us
                </Typography>
              </dd>
              <dd>
                <Typography variant="h5" color="contrastText">
                  Contact Us
                </Typography>
              </dd>
          </Grid>
          <Grid item xs={12} md={4}>
            <dd>
              <Typography variant="h5" color="contrastText">
                Warranty
              </Typography>
            </dd>
            <dd>
              <Typography variant="h5" color="contrastText">
                Privacy Policy
              </Typography>
            </dd>
            <dd>
              <Typography variant="h5" color="contrastText">
                Terms &amp; Conditions
              </Typography>
            </dd>
          </Grid>
          <Typography variant="h6" color="contrastText">
            © 2020 Khata Corner All Rights Reserved.
          </Typography>
        </Grid>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
