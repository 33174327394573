import axios from 'src/utils/axios';


class MgmtServiceService {

  createService = (newService) => new Promise((resolve, reject) => {

    const payload = newService;

    //console.log("MgmtServiceService.createService:" + JSON.stringify(payload));

    axios.post('/api/accounts/service/create', {payload})
    .then((response) => {
      //console.log("MgmtServiceService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let services = response.data.payload;
        resolve(services);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtServiceService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateService = (editService) => new Promise((resolve, reject) => {

    const payload = editService;

    //console.log("MgmtServiceService.updateService:" + JSON.stringify(payload));

    axios.post('/api/accounts/service/update', {payload})
    .then((response) => {
      //console.log("MgmtServiceService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let service = response.data.payload;
        resolve(service);
      } else {
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtServiceService.createService (Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })


  fetchServiceInfo = (businessId, userId) => new Promise((resolve, reject) => {

    const payload = { businessId: businessId, userId: userId};

    //console.log("MgmtServiceService.fetchServiceInfo:" + JSON.stringify(payload));

    axios.get('/api/accounts/service/services', { params: {businessId : businessId}})
    .then((response) => {
      //console.log("MgmtServiceService(Response):" + JSON.stringify(response));

      if(response.data.payload){
        let services = response.data.payload;
        resolve(services);
      } else {
        resolve(null);
        reject(response.data.error);
      }
    })
    .catch((error) => {
      //console.log("MgmtClassesService.fetchClassInfo (Response Error):" + JSON.stringify(error));
      resolve(null);
      reject(error);
    });

  })


}



const mgmtServiceService = new MgmtServiceService();

export default mgmtServiceService;
