class UserProfile {
  constructor(userInfo){
  //constructor(id, email, mobile) {
    //console.log('NewBusinessReqInfo: ' + JSON.stringify(userInfo));

    this.id = userInfo.id;
    this.email = userInfo.email;
    this.mobile = userInfo.mobile;

    //id =  '5e86809283e28b96d2d38537';
    this.avatar = userInfo.avatar;
    this.bio = 'Sales Manager';
    this.canHire = userInfo.canHire;
    this.dob = userInfo.dob;
    this.name = userInfo.name;
    this.state = userInfo.state;
    this.country = userInfo.country;
    //email= 'katarina.smith@devias.io';

    this.username = userInfo.username;
    this.isPublic = true;
    //mobile= '+40 777666555';
    this.role = 'admin';  // TODO_Sunil remove hardcoding

    this.timezone = '4:32PM (GMT-4)';

    this.activeBusinessId = userInfo.activeBusinessId;
    this.activeBusiness = userInfo.activeBusiness;
    this.activeRole = userInfo.activeRole;

    this.businessInfo = userInfo.businessInfo;

    this.businessList = [];
  }

  addBusiness(business){
    //console.log('UserProfile:' + JSON.stringify(business));
    this.businessList.push(business);
  }
}

class UserProfileUpdate {
  constructor(businessId, email, info){
    //console.log("UserProfileUpdate:", businessId, email, info);

    this.email = info.email;
    this.name = info.name;
    this.dob = info.dob;
    this.mobile = info.mobile;
    this.state = info.state;
    this.country = info.country;

  }
}



class PasswordInfo {
  constructor(businessId, email, info){
    //console.log("PasswordInfo:", businessId, email, info);

    this.businessId = businessId;
    this.email = email;
    this.password = info.password;
    this.newPassword = info.newPassword;
  }
}


// TODO SP : Remove the hardcoding by updating right field information

export default { UserProfile };
export { UserProfile , PasswordInfo, UserProfileUpdate};
