
class Product {

  constructor(businessId, updatedBy, info) {
    //console.log('Product(): ' + JSON.stringify(info));

    this.businessId = businessId;
    this.businessName = businessId;

    this.productCode = info.productCode;
    this.productName = info.productName;
    this.description = info.description;

    this.hsnCode = info.hsnCode;
    this.uom = info.uom;

    this.includesTaxes = info.includesTaxes;
    this.isTaxable = info.isTaxable;
    this.price = info.price;
    this.salePrice = info.salePrice;

    this.cgst = info.cgst;
    this.sgst = info.sgst;
    this.igst = info.igst;
    this.cess = info.cess;

    this.updatedBy = updatedBy;
  }
}

export default { Product };
export { Product };
