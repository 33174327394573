import React, {
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import mgmtClassesService from 'src/services/mgmtClassesService';
import { setClassesData } from 'src/actions/mgmtClassesAction';
import mgmtServiceService from 'src/services/mgmtServiceService';
import { setServiceData } from 'src/actions/mgmtServicesAction';
import mgmtCustomerService from 'src/services/mgmtCustomerService';
import { setCustomerData } from 'src/actions/mgmtCustomersAction';


function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {


        //console.log("Auth|Before loginInWithToken ");

        const userId = authService.getUserId();

        //console.log("Auth|Before loginInWithToken :" + userId);

        try {
        const user = await authService.loginInWithToken(userId);
        await dispatch(setUserData(user));

        //console.log("Auth|After loginInWithToken " + JSON.stringify(user));

        const classInfo = await mgmtClassesService.fetchClassInfo(user.activeBusinessId, user.email);
        await dispatch(setClassesData(classInfo));

        //console.log("Auth|After fetchClassInfo " + JSON.stringify(classInfo));

        const services = await mgmtServiceService.fetchServiceInfo(user.activeBusinessId, user.email);
        await dispatch(setServiceData(services));

        const customers = await mgmtCustomerService.fetchCustomerInfo(user.activeBusinessId, user.email);
        await dispatch(setCustomerData(customers));
      } catch (error) {
        console.log("General: ", error.message);
      }

        //console.log("Auth|After loginInWithToken Services: ",services);

      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
