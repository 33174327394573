import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import {UserProfile} from 'src/interface/authIf';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          this.setUserId(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
      this.setUserId(null);
    }
  }

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
  //  axios.post('/api/account/login', { email, password })


  //axios.post('/api/user/login', { email, password })
    axios.post('/api/aggregator/business/login', { email, password })
      .then((response) => {

        if (response.data.payload) {
          let userInfo = response.data.payload.user;
        //  let tmpaccount = response.data.payload.account;  // TODO map to UserProfile
          let businessList = response.data.payload.businessList;
          let businessInfo = response.data.payload.businessInfo;

          //console.log("Response: " + JSON.stringify(response.data.payload));

          //this.setSession(response.data.token);
          this.setUserId(email);
          this.setSession(userInfo.token);





          const business = {
            businessAddress: {
              address1: 'B-89, XYZ Building',
              address2: 'Gandhi Road',
              city: 'Hobli',
              state: 'Karnataka',
              country: 'India',
              pincode: '560126'
            },
            email: 'ab13@digi.c',
            contacts: '+80 4021 0210, +40 2323 2323'
          };


          let tmpUser = {
            id: userInfo.id,
            email: userInfo.email,
            mobile: userInfo.mobile,
            avatar: '/static/images/avatars/avatar.png',  // TODO: See how to change it to user photo
            bio: 'Manager',       // TODO: Need to get the user type from backend
            canHire:false,

            name: userInfo.name,
            state: userInfo.state,
            country: userInfo.country,
            dob: userInfo.dob,

            timezone: '4:32PM (GMT-4)', // TODO: 'Hardcoding to be removed
            username: userInfo.name,

            role: 'admin',

            activeBusinessId: userInfo.activeBusinessId,
            activeBusiness: userInfo.activeBusiness, // TODO: This needs to be coming from server
            activeRole: userInfo.activeRole,   // TODO: This needs to be coming from server

            //businessInfo: userInfo.businessInfo,  // TODO_Sunil: Replace this with values from backend
            businessInfo: businessInfo,

            password: '*****',  // TODO: Need to check where it is used
            isPublic: false,
          };

          let user = new UserProfile(tmpUser);

          for (var i=0; i<businessList.length; i++){
            let business =  { id: businessList[i].businessId, name: businessList[i].businessName, role:businessList[i].role };
              user.addBusiness(business);
            }

          //console.log("loginWithEmailAndPassword->User: " + JSON.stringify(user));
          // TODO SP : User profile is partially hard coded need to fix it with relevant data

          resolve(user);
        } else {
          //console.log("loginWithEmailAndPassword->data.error" + JSON.stringify(response.data.error));
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("loginWithEmailAndPassword->error" + JSON.stringify(error));

        reject(error);
      });
  })



  loginInWithToken = (userId) => new Promise((resolve, reject) => {
    //axios.get('/api/account/me')
    //let userId = "ab13@digi.c";
    //console.log("loginInWithToken-> For User: " + userId);
    axios.get('/api/aggregator/business/user-profile', { params: {userId: userId}})
      .then((response) => {
        if (response.data.payload) {

          let userInfo = response.data.payload.user;
          let businessList = response.data.payload.businessList;
          let businessInfo = response.data.payload.businessInfo;

          /*const businessInfo = {
            businessAddress: {
              address1: 'B-89, XYZ Building',
              address2: 'Gandhi Road',
              city: 'Bangalore',
              state: 'Karnataka',
              country: 'India',
              pincode: '560126'
            },
            email: 'ab13@digi.c',
            contacts: '+80 4021 0210, +40 2323 2323'
          };
*/

          let tmpUser = {
            id: userInfo.id,
            email: userInfo.email,
            mobile: userInfo.mobile,
            avatar: '/static/images/avatars/avatar.png',  // TODO: See how to change it to user photo
            bio: 'Manager',       // TODO: Need to get the user type from backend
            canHire:false,

            name: userInfo.name,
            state: userInfo.state,
            country: userInfo.country,
            dob: userInfo.dob,

            timezone: '4:32PM (GMT-4)', // TODO: 'Hardcoding to be removed
            username: userInfo.name,


            role: 'admin',

            activeBusinessId: userInfo.activeBusinessId,
            activeBusiness: userInfo.activeBusiness, // TODO: This needs to be coming from server
            activeRole: userInfo.activeRole,   // TODO: This needs to be coming from server

            //businessInfo: userInfo.businessInfo,  // TODO_Sunil: Replace this with values from backend
            businessInfo: businessInfo,

            password: '*****',  // TODO: Need to check where it is used
            isPublic: false,
          };


          let user = new UserProfile(tmpUser);

          for (var i=0; i<businessList.length; i++){
            let business =  { id: businessList[i].businessId, name: businessList[i].businessName, role:businessList[i].role };
              user.addBusiness(business);
            }

            //console.log("loginInWithToken->User: " + JSON.stringify(user));


          resolve(user);
        } else {
          //console.log("loginInWithToken->reject" + JSON.stringify(response.data.error));
          reject(response.data.error);
        }
      })
      .catch((error) => {
        //console.log("loginInWithToken->error" + JSON.stringify(error));
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
    this.setUserId(null);
  }

  setUserId = (userId) => {
    if(userId){
      localStorage.setItem('userId',userId);
    }else{
      localStorage.removeItem('userId');
    }
  }


  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  getUserId = () => localStorage.getItem('userId');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }


  changePassword = (passInfo) => new Promise((resolve, reject) => {

    //console.log("changePassword() Service:" + JSON.stringify(passInfo));

    const payload = passInfo;

    axios.post('/api/user/change-password', {payload})
    .then((response) => {
      //console.log("changePassword(response)" + JSON.stringify(response));

      if(response.data.error){
        reject(response.data.error);
      } else {
        resolve(response.data.message);
      }

    })
    .catch((error) => {
      //console.log("changePassword() Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })

  updateProfile = (userInfo) => new Promise((resolve, reject) => {

    //console.log("updateProfile() Service:" + JSON.stringify(userInfo));

    const payload = userInfo;

    axios.post('/api/user/update-profile', {payload})
    .then((response) => {
      //console.log("updateProfile(response)" + JSON.stringify(response));

      if(response.data.error){
        reject(response.data.error);
      } else {
        resolve(response.data.payload);
      }
    })
    .catch((error) => {
      //console.log("updateProfile() Service(Response Error):" + JSON.stringify(error));
      reject(error);
    });
  })



  isAuthenticated = () => !!this.getAccessToken()


}

const authService = new AuthService();

export default authService;
