import mgmtOrderService from 'src/services/mgmtOrderService';

import { Order, OrderStatus, SplitOrder } from 'src/interface/orderIf';

export const ORDER_CREATE ='@order/create';
export const ORDER_CREATE_SUCCESS ='@order/create-success';
export const ORDER_CREATE_FAILURE ='@order/create-failure';

export const ORDER_UPDATE = '@order/update';
export const ORDER_UPDATE_SUCCESS = '@order/update-success';
export const ORDER_UPDATE_FAILURE = '@order/update-failure';

export const ORDER_SPLIT = '@order/split';
export const ORDER_SPLIT_SUCCESS = '@order/split-success';
export const ORDER_SPLIT_FAILURE = '@order/split-failure';


export function createOrder(businessId, userId, orderInfo, order) {
  return async (dispatch) => {
    //console.log("createOrder():", businessId, userId, orderInfo, order );
    let newOrder = new Order(businessId, userId, orderInfo, order);
    newOrder.businessId = businessId;
    newOrder.addedBy = userId;

    //console.log("createOrder():" + JSON.stringify(orderInfo) + " " + JSON.stringify(newOrder));

    try {
      dispatch({ type: ORDER_CREATE });

      const orderInfo = await mgmtOrderService.createOrder(newOrder);

      //console.log("createOrder()(after):" + JSON.stringify(orderInfo));
      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: {
          orderInfo
        }
      });
    } catch (error) {
      dispatch({ type: ORDER_CREATE_FAILURE });
      throw error;
    }
  };
}

export function updateOrder(businessId, userId, orderId, orderInfo, order) {
  return async (dispatch) => {
    //console.log("updateOrder():", businessId, userId, orderInfo, order );

    // TODO: Check if the email id and orderInfo.email is different

    let newOrder = new Order(businessId, userId, orderInfo, order);
    newOrder.businessId = businessId;
    newOrder.addedBy = userId;
    newOrder.orderId = orderId;

    //console.log("updateOrder():" + JSON.stringify(orderInfo) + " " + JSON.stringify(newOrder));

    try {
      dispatch({ type: ORDER_UPDATE });

      const orderInfo = await mgmtOrderService.updateOrder(newOrder);

      //console.log("createOrder()(after):" + JSON.stringify(orderInfo));
      dispatch({
        type: ORDER_UPDATE_SUCCESS,
        payload: {
          orderInfo
        }
      });
    } catch (error) {
      dispatch({ type: ORDER_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function updateOrderStatus(businessId, userId, statusInfo) {
  return async (dispatch) => {
    //console.log("updateOrderStatus():", businessId, userId, statusInfo );


    let orderStatus = new OrderStatus(businessId, userId, statusInfo);
    orderStatus.businessId = businessId;
    orderStatus.addedBy = userId;

    //console.log("updateOrderStatus():" + JSON.stringify(statusInfo) + " " + JSON.stringify(orderStatus));

    try {
      dispatch({ type: ORDER_UPDATE });

      const orderInfo = await mgmtOrderService.updateOrderStatus(orderStatus);

      //console.log("updateOrderStatus()(after):" + JSON.stringify(orderInfo));
      dispatch({
        type: ORDER_UPDATE_SUCCESS,
        payload: {
          orderInfo
        }
      });
    } catch (error) {
      dispatch({ type: ORDER_UPDATE_FAILURE });
      throw error;
    }
  };
}

export function createSplitOrder(businessId, userId, orderId, order, newOrder, splitItems) {
  return async (dispatch) => {
    console.log("updateOrder():", businessId, userId, order, newOrder, splitItems );

    // TODO: Check if the email id and orderInfo.email is different
    let newSplitOrder = new SplitOrder( businessId, userId, order, newOrder, splitItems);

    newSplitOrder.businessId = businessId;
    newSplitOrder.addedBy = userId;
    newSplitOrder.orderId = orderId;

    console.log("splitOrder():", newSplitOrder);

    try {
      dispatch({ type: ORDER_SPLIT });

      const orderInfo = await mgmtOrderService.createSplitOrder(newSplitOrder);

      console.log("createOrder()(after):" + JSON.stringify(orderInfo));
      dispatch({
        type: ORDER_SPLIT_SUCCESS,
        payload: {
          orderInfo
        }
      });
    } catch (error) {
      dispatch({ type: ORDER_SPLIT_FAILURE });
      throw error;
    }
  };
}
