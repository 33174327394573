class ClassInfo {
  constructor(info) {
    //console.log('ClassInfo: ' + JSON.stringify(info));

    this.classId = info.classId;
    this.name = info.name;
    this.desc = info.desc;
    this.startedOn = new Date();
    this.registrationFee = info.registrationFee;
    this.monthlyFee = info.monthlyFee;
  }
}


class Teacher {
  constructor(info) {
    //console.log('Teacher: ' + JSON.stringify(info));
    this.empId = info.empId;
    this.refEmpId = info.refEmpId;
    this.name = info.name;
    this.skills = info.skills;
    this.email = info.email;
    this.mobile = info.mobile;
    this.joiningDate = info.joiningDate;
  }
}

class Student {
  constructor(info) {
    //console.log('Student: ' + JSON.stringify(info));

    this.studentId = info.studentId;
    this.name = info.name;
    this.age = info.age;
    this.gender = info.gender;
    this.mobile = info.mobile;
    this.parentName = info.parentName;
    this.email = info.email;
    this.joiningDate = info.joiningDate;
  }
}

class Batch {
  constructor(info) {
    //console.log('Batch: ' + JSON.stringify(info));
    let teacherArray = info.teacher.split(",");
    let classArray = info.classIdName.split(",");

    this.batchId = info.batchId;
    this.name = info.name;
    this.classInfo = { classId: classArray[0], className: classArray[1]};

//    this.className = info.className;
    this.teacher = { empId: teacherArray[0], name: teacherArray[1]};
//    this.teacher = info.teacher;
    this.desc = info.desc;
    this.location = info.location;
    this.startedOn = info.startedOn;
    this.timings = [{ days: info.days, startTime: info.startTime, duration: info.duration}];

  }
}

class BatchStudents {
  constructor(info) {
    //console.log('Batch: ' + JSON.stringify(info));
    let teacherArray = info.teacherIdName.split(",");
    let batchArray = info.batchIdName.split(",");
    let studentArray = info.studentIdName.split(",");

    this.batchId = batchArray[0];
    this.name = batchArray[1];
    this.students = { studentId: studentArray[0], name: studentArray[1], startDate: info.startDate, notes: info.notes};

  }
}

class Attendance {
  constructor(batchIdName, attendanceDate, studentListP, studentListA) {
    //console.log('Attendance: ' );

    let batchArray = batchIdName.split(",");

    this.batchId = batchArray[0];
    this.batchName = batchArray[1];

    this.attendanceDate = attendanceDate;
    //this.presence = presence;  // Present or Absent
    //this.students = students;  //[ { studentId, studentName}, ]

    let preList = [];
    studentListP.forEach(function(student){
      let stdArray = student.split(",");
      preList.push({studentId: stdArray[0], name: stdArray[1]});
    })
    this.attendees = preList;

    let abList = [];
    studentListA.forEach(function(student){
      let stdArray = student.split(",");
      abList.push({studentId: stdArray[0], name: stdArray[1]});
    })
    this.absentees = abList;

  }
}

class FeeReceipt {

  constructor(receiptInfo) {
    //console.log('FeeReceipt' + JSON.stringify(receiptInfo));
    this.receiptNo = receiptInfo.receiptNo;
    this.receiptDate = receiptInfo.receiptDate;
    this.receivedFrom = receiptInfo.receivedFrom;

    this.paymentForMonth = receiptInfo.paymentForMonth;
    this.paymentType = receiptInfo.paymentType;
    this.paymentDetails = receiptInfo.paymentDetails;
    this.amount = receiptInfo.amount;
    this.createdOn = new Date();  // To be filled by server

    let studentArray = receiptInfo.studentIdName.split(",");
    this.student = { studentId: studentArray[0], name: studentArray[1]};

    let batchArray = receiptInfo.batchIdName.split(",");
    this.batch = { batchId: batchArray[0], name: batchArray[1]};

  }
};

// TODO SP : Add more fees structure

export default { ClassInfo };
export { ClassInfo, Teacher, Student, Batch, BatchStudents, Attendance, FeeReceipt };
