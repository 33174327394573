/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CUSTOMER_INFO,
  CUSTOMER_CREATE,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_CREATE_FAILURE,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAILURE
} from 'src/actions/mgmtCustomersAction';

const initialState = {
  customerInfo: {
    customers: []
  }
};

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_INFO: {
      //console.log('customersReducer: Payload ' + JSON.stringify(action.payload));

      const { customers } = action.payload;

      //console.log("customersReducer: customers --> " + JSON.stringify(customers) );

      return produce(state, draft => {
        draft.customerInfo.customers = customers;
      });
    }

    // TODO: This clean may not be required here
    case CUSTOMER_CREATE: {
      return produce(state, draft => {
        // Ensure we clear current session
        //    draft.user = null;
      });
    }

    case CUSTOMER_CREATE_SUCCESS: {
      //console.log("mgmtClassesReducer: Payload " + JSON.stringify(action.payload));

      const { customer } = action.payload;

      //console.log("mgmtClassesReducer: classesInfo" + JSON.stringify(classesInfo) );

      // TODO: SP check this should just added the record to customers
      return produce(state, draft => {
        draft.customerInfo.customers.push(customer);
      });
    }

    case CUSTOMER_CREATE_FAILURE: {
      return produce(state, () => {
        // Maybe store error
        //console.log("Todo: Failure needs to be handled");
      });
    }

    default: {
      return state;
    }
  }
};

export default customersReducer;
