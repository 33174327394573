
class Invoice {

  constructor(businessId, addedBy, order) {
    //console.log('Invoice(): ' + JSON.stringify(order), order);

    this.businessId = businessId;
    this.businessName = businessId;

    this.orderId = order.orderId;
    this.orderDate = order.orderDate;
    this.billTo = order.billTo;
    this.shipTo =  order.shipTo;

    this.items = order.items;

    this.summary = {
      totalBeforeTax : order.summary.totalBeforeTax,
      totalCGST: order.summary.totalCGST,
      totalSGST: order.summary.totalSGST,
      totalIGST: order.summary.totalIGST,
      totalcess: order.summary.totalcess,
      totalTax: order.summary.totalTax,
      total: order.summary.total,
    }

    this.authorizedBy = order.authorizedBy;
    this.bankDetails = order.bankDetails;

    this.status = order.status;
    this.payment = order.payment;

    this.comments = order.comments;
    this.refPO = order.refPO;

    this.addedBy = addedBy;
  }
}

export default { Invoice };
export { Invoice };
