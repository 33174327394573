class ContactUs {
  constructor(info){
    this.name= info.name;
    this.mobile= info.mobile;
    this.email= info.email;
    this.comments= info.comments;
  }
}

export { ContactUs};
export default { ContactUs };
