/* eslint-disable import/prefer-default-export */
export const THEMES = {
  KHATA_CORNER: 'KHATA_CORNER',
  KHATA_CORNER_BLUE_JEANS: 'KHATA_CORNER_BLUE_JEANS',
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  KHATA_CORNER_BURGUNDY: 'KHATA_CORNER_BURGUNDY'
};

export const BUSINESS_TYPES = {
  GENERAL: 'general',
  CLASSES: 'classes',
  CONSULTANT: 'consulant',
  SERVICES: 'services',
  MANUFACTURING: 'manufacturing'
}

export const USER_ROLES = {
  OWNER: 'OWNER',
  ACCOUNTANT: 'ACCOUNTANT',
  AUDITOR: 'AUDITOR',
  MANAGER: 'MANAGER',
  TEACHER: 'TEACHER'
}



export const BUSINESS_CATEGORIES = [
  {
    id: BUSINESS_TYPES.GENERAL,
    name: 'General'
  },
  {
    id: BUSINESS_TYPES.CLASSES,
    name: 'Classes'
  },
  {
    id: BUSINESS_TYPES.CONSULTANT,
    name: 'Consultant'
  },
  {
    id: BUSINESS_TYPES.SERVICES,
    name: 'Services'
  },
  {
    id: BUSINESS_TYPES.MANUFACTURING,
    name: 'Manufacturing'
  }
];

export const TAX_TYPE = {
  EXEMPTED: 'EXEMPTED',
  COMPOSITION: 'COMPOSITION',
  REGULAR: 'REGULAR'
}

export const BUSINESS_TAX_TYPE_OPTIONS = [
  {
    code: TAX_TYPE.EXEMPTED,
    name: 'Exempted'
  },
  {
    code: TAX_TYPE.COMPOSITION,
    name: 'Composition'
  },
  {
    code: TAX_TYPE.REGULAR,
    name: 'Regular'
  }
];
