import {
  Activity as ActivityIcon,
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
} from "react-feather";


import BusinessIcon from '@material-ui/icons/Business';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SettingsIcon from '@material-ui/icons/Settings';

const AdminNavConfig = [
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/admin_base/dashboard",
      },
/*      {
        title: "Dashboard Financial",
        icon: BarChartIcon,
        href: "/app/dashboard/dashboard-financial",
      }, */
    ],
  },
  {
    subheader: "Management",
    items: [
      {
        title: "Accounts",
        icon: BusinessIcon,
        href: "/admin_base/workshop",
        items: [
          {
            title: "Accounts",
            href: "/admin_base/accounts",
          },
        ],
      },
      {
        title: "Products & Pricing",
        icon: MonetizationOnIcon,
        href: "/admin_base/products",
        items: [
          {
            title: "Products",
            href: "/admin_base/products",
          },
          {
            title: "Pricing",
            href: "/admin_base/products",
          },
        ],
      },
      {
        title: "Settings",
        icon: SettingsIcon,
        href: "/admin_base/configurations",
        items: [
          {
            title: "Base Configurations",
            href: "/admin_base/configurations/base",
          },
          {
            title: "Email Configurations",
            href: "/admin_base/configurations/email",
          },
        ],
      },
    ],
  },
];

export default AdminNavConfig;
