/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  PRODUCTS_INFO,
  PRODUCT_CREATE,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAILURE,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE
} from 'src/actions/mgmtProductsAction';

const initialState = {
  productInfo: {
    products: []
  }
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_INFO: {
      //console.log('productsReducer: Payload ' + JSON.stringify(action.payload));

      const { products } = action.payload;

      //console.log("productsReducer: products --> " + JSON.stringify(products) );

      return produce(state, draft => {
        draft.productInfo.products = products;
      });
    }

    // TODO: This clean may not be required here
    case PRODUCT_CREATE: {
      return produce(state, draft => {
        // Ensure we clear current session
        //    draft.user = null;
      });
    }

    case PRODUCT_CREATE_SUCCESS: {
      //console.log("mgmtClassesReducer: Payload " + JSON.stringify(action.payload));

      const { product } = action.payload;

      //console.log("mgmtClassesReducer: classesInfo" + JSON.stringify(classesInfo) );

      // TODO: SP check this should just added the record to products
      return produce(state, draft => {
        draft.productInfo.products.push(product);
      });
    }

    case PRODUCT_CREATE_FAILURE: {
      return produce(state, () => {
        // Maybe store error
        //console.log("Todo: Failure needs to be handled");
      });
    }

    default: {
      return state;
    }
  }
};

export default productsReducer;
