/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ORDER_CREATE,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAILURE,
  ORDER_UPDATE,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAILURE
} from 'src/actions/mgmtOrdersAction';

const initialState = {
  orderInfo: {
    orders: []
  }
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {

/*    case ORDERS_INFO: {

      //console.log("mgmtClassesReducer: Payload " + JSON.stringify(action.payload));

      const {orderInfo} = action.payload;

      //console.log("mgmtClassesReducer: classesInfo" + JSON.stringify(classesInfo) );

      return produce(state, (draft) => {
        draft.orderInfo = orderInfo;
      });
    }*/




    // TODO: This clean may not be required here
    case ORDER_CREATE: {
      return produce(state, (draft) => {
        // Ensure we clear current session
    //    draft.user = null;
      });
    }

    case ORDER_CREATE_SUCCESS: {

      //console.log("mgmtClassesReducer: Payload " + JSON.stringify(action.payload));

      const { orderInfo } = action.payload;

      //console.log("mgmtClassesReducer: classesInfo" + JSON.stringify(classesInfo) );

      return produce(state, (draft) => {
        draft.orderInfo = orderInfo;
      });
    }

    case ORDER_CREATE_FAILURE: {
      return produce(state, () => {
        // Maybe store error
        //console.log("Todo: Failure needs to be handled");
      });
    }


    default: {
      return state;
    }
  }
};

export default ordersReducer;
