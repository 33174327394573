import axios from 'axios';
import https from 'https';
import * as fs from 'fs';

const gateway_port = process.env.REACT_APP_API_PORT || 5000;
//const url = 'https://' + window.location.hostname + ':' + gateway_port;

const url = process.env.REACT_APP_API_HOSTNAME

console.log(
  'Axios URL : ' + url 
);
/*
if (process.env.NODE_ENV === 'development'){ 
  const httpsAgent = new https.Agent({ 
    rejectUnauthorized: false,
    //cert: fs.readFileSync("./../../../50adae0585578f86.crt"),
    //key: fs.readFileSync("./../../../khatacorner.key"),
    //ca:  fs.readFileSync("./../../../gd_bundle-g2-g1.crt")
  });

  axios.defaults.options = httpsAgent;
 }
*/

const instance = axios.create({
  //baseURL: 'http://localhost:5000/',
  //baseURL: 'http://34.87.48.170:5000/',
  //  baseURL: process.env.REACT_APP_API_URL,
  baseURL: url,
  timeout: 30000,
});

export default instance;
