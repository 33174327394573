/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE_SUCCESS,
  SWITCH_BUSINESS,
  UPDATE_BUSINESS_ADDRESS,
  UPDATE_BUSINESS_ADDRESS_SUCCESS,
  UPDATE_BUSINESS_ADDRESS_FAILURE,
  ADD_BUSINESS_USER_SUCCESS,
  UPDATE_BUSINESS_TNC_SUCCESS
} from 'src/actions/accountActions';


const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE_SUCCESS: {
      const { userData } = action.payload;

      return produce(state, (draft) => {
        //draft.user = user;

        draft.user.name = userData.name;
        draft.user.mobile = userData.mobile;
        draft.user.dob = userData.dob;
        draft.user.state = userData.state;
        draft.user.country = userData.country;

      });
    }

    case UPDATE_BUSINESS_ADDRESS_SUCCESS:
    case ADD_BUSINESS_USER_SUCCESS:
    case UPDATE_BUSINESS_TNC_SUCCESS: {
      const { businessInfo } = action.payload;

      return produce(state, (draft) => {
        draft.user.businessInfo = businessInfo;
      });
    }


    case SWITCH_BUSINESS: {
      const { activeBusinessId } = action.payload;

      console.log("SWITCH_BUSINESS", action, activeBusinessId);

      return produce(state, (draft) => {
        draft.user.activeBusinessId = activeBusinessId;
        // TODO: Should get Business and Active Role also from backend
      });
    }


    default: {
      return state;
    }
  }
};

export default accountReducer;
