import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import { contactUs } from "src/actions/contactActions";


import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  editor: {
    "& .ql-editor": {
      height: 400,
    },
  },
}));

function ContactUsForm({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        name: "",
        mobile: "",
        email: "",
        comments: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(45).required("Name is required"),
        mobile: Yup.string()
          .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
          .required("Mobile# is required"),
        email: Yup.string().max(120).required("Email is required"),
        comments: Yup.string().max(200),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(contactUs(values));
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar("Thanks for contacting us", {
            variant: "success",
          });

          values.name = "";
          values.mobile = "";
          values.email = "";
          values.comments = "";

        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Card>
                <CardHeader className={classes.header} title="Contact US" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label="Name"
                        name="name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.mobile && errors.mobile)}
                        fullWidth
                        helperText={touched.mobile && errors.mobile}
                        label="Mobile"
                        margin="normal"
                        name="mobile"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        value={values.mobile}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email"
                        name="email"
                        type="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.comments && errors.comments)}
                        fullWidth
                        helperText={touched.comments && errors.comments}
                        label="Comments"
                        name="comments"
                        type="text"
                        multiline
                        rows={4}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.comments}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                  <Box mt={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Box mt={4} />
          </Grid>
          <Box mt={4} />
        </form>
      )}
    </Formik>
  );
}

ContactUsForm.propTypes = {
  className: PropTypes.string,
};

export default ContactUsForm;
